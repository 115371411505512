export default class UsersService {
  constructor(axios) {
    this.axios = axios;
  }

  getConfig() {
    let token = localStorage.getItem("token");
    return {
      headers: { Authorization: `Bearer ${token}` },
    };
  }

  async createUser(formData) {
    let url = "users/create";
    return await this.axios.post(url, formData);
  }

  async getUsersList() {
    let url = "users/list";
    return await this.axios.get(url, this.getConfig());
  }

  async deleteUser(id) {
    let url = "users/delete/" + id;
    return await this.axios.delete(url, this.getConfig());
  }

  async setAdmin(email) {
    let url = "/auth/set-admin";
    return await this.axios.post(url, { email }, this.getConfig());
  }

  async setModifier(email) {
    let url = "/auth/set-modifier";
    return await this.axios.post(url, { email }, this.getConfig());
  }

  async setComum(email) {
    let url = "/auth/set-comum";
    return await this.axios.post(url, { email }, this.getConfig());
  }

  async blockUser(id) {
    let url = `/users/block/${id}`;
    return await this.axios.post(url, {}, this.getConfig());
  }

  async unblockUser(id) {
    let url = `/users/unblock/${id}`;
    return await this.axios.post(url, {}, this.getConfig());
  }

  async recoverPassword(email, password, token) {
    let url = `/users/change-password/${token}`;
    return await this.axios.post(url, { email, password });
  }

  sortUsers(users, key){
    return users.sort(function(a, b) {
      var x = a[key];
      var y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }
}
