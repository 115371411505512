<template>
  <div class="filter-area">
    <img src="../../assets/logo.png" alt="Logo Azemute" class="logo" />
    <div class="search-input">
      <span @click="filter = !filter" class="material-icons white"
        >filter_alt</span
      >
      <input type="text" v-model="search" />`~`
      <span class="material-icons white">search</span>
    </div>

    <transition name="modal">
      <div v-show="filter" class="filter-modal white">
        <div
          v-for="{ label, color } in subtitles"
          :key="label"
          class="subtitles"
          @click="handleInputColor(label)"
        >
          <div class="line">
            <div
              class="quad-color"
              :class="value.color === label ? 'quad-color--selected' : ''"
              :style="`background:${color};`"
            ></div>
          </div>
        </div>
        <div class="subtitles" @click="clearFilter()">
          <div class="line">
            <div class="quad-color" :style="`background:grey;`">X</div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  watch: {
    search: function (newSearch) {
      this.handleInput(newSearch, this.value.color);
    },
  },
  data() {
    return {
      filter: false,
      search: "",
      subtitles: [
        {
          label: "ATRASADO",
          color: "#a00",
        },
        {
          label: "EM DIA",
          color: "#0a0",
        },
        {
          label: "ESCRITURA",
          color: "#fff",
        },
        {
          label: "ESTOQUE",
          color: "#aaa",
        },
        {
          label: "INDEFINIDO",
          color: "#d91",
        },
        {
          label: "QUITADO",
          color: "#00a",
        },
        {
          label: "RESERVADO",
          color: "#aa2",
        },
      ],
    };
  },
  props: {
    value: {
      required: false,
    },
  },
  methods: {
    handleInput(search, color) {
      let value = { search, color };
      // console.log(value);
      this.$emit("input", value);
    },
    handleInputColor(color) {
      let value = {
        search: this.search,
        color: color,
      };
      this.$emit("input", value);
    },
    clearFilter() {
      let value = {
        search: this.value.search,
        color: "",
      };
      this.$emit("input", value);
    },
  },
};
</script>

<style scoped>
.filter-area {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  height: 60px;
  background-color: #0b245b;
}

.filter-area .search-input {
  border-radius: 2px;
  padding: 4px;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
}

.filter-area .search-input span {
  font-size: 16px;
  cursor: pointer;
}

.filter-area .search-input input {
  display: flex;
  width: 190px;
  flex: 1;
  align-items: center;
  background: none;
  outline: none;
  border: none;
}

.filter-modal {
  background-color: #0b245b;
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  width: 100px;
  height: 64;
  padding: 4px;
  border-radius: 0px 4px 4px 0px;
  top: 0px;
  left: 320px;
}

.quad-color {
  margin-right: 8px;
  margin: 4px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  border: 1px solid transparent;
}

.quad-color--selected {
  margin-right: 8px;
  margin: 4px;
  cursor: pointer;
  width: 15px;
  height: 15px;
  border: 1px solid white;
}

.logo {
  height: 24px;
  margin-right: 8px;
  filter: brightness(0) invert(1);
}

.modal-enter,
.modal-leave-to {
  -webkit-animation: slide 0.5s forwards;
  -webkit-animation-delay: 2s;
  animation: slide 0.5s forwards;
  animation-delay: 2s;
  opacity: 0;
}

.modal-enter-active,
.modal-leave-active {
  transition: all 0.3s;
}

.white {
  color: white;
}
@media screen and (min-width: 480px) and (max-width: 768px) {
  .filter-area .search-input input {
    width: 140px;
  }
  .filter-modal {
    left: 300px;
  }
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  .filter-area .search-input input {
    width: 140px;
  }
  .filter-modal {
    left: 180px;
    z-index: 5;
  }
}
</style>
