import axios from "axios";
import FileService from '../services/FileService';
import LoteService from '../services/LoteService';
import UserService from "../services/UserService";

axios.defaults.baseURL = "https://itasol.com.br/api";
// axios.defaults.baseURL = "http://localhost:4000";

let userService = new UserService(axios);
let fileService = new FileService(axios);
let loteService = new LoteService(axios);

export default (Vue) => {
  Vue.prototype.$http = axios;
  Vue.prototype.$userService = userService;
  Vue.prototype.$fileService = fileService;
  Vue.prototype.$loteService = loteService;
};
