<template>
    <div class="mobileBar" @click.stop>
        <Button>
            <div class="button">
              <span class="material-icons-outlined" @click ="$emit('openLateralBar')">
              view_headline
              </span>
            </div>
        </Button>
    </div>
</template>

<script>
import Button from './Button'
export default {
    components: {
    Button
  },
    data(){
        return {
            showLateralBar : true,
        };
    },
    methods: {
      handleClick(){
        console.log("teste");
      }
    }

}
</script>

<style scoped>
.mobileBar {
  position: absolute;
  top: 1.6%;
  left: 2%;
  z-index: 10;
}
.button{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 32px;
}
.material-icons-outlined{
  font-size: 1.8rem;
}
</style>