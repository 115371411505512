<template>
  <div id="recover-container">
      <h1 id="itasol-home" @click="$router.push('/')">Itasol</h1>
    <div id="recover-content">
      <h1 id="title-recover">Nova Senha</h1>
      <div class="recover-field">
        <input
          v-model="email"
          type="email"
          placeholder="E-mail"
        />
      </div>
      <div class="recover-field">
        <input
          v-model="password"
          :type="see_password ? `text` : `password`"
          placeholder="Sua nova senha"
        />
        <span
          v-if="see_password"
          class="material-icons icon"
          @click="see_password = false"
        >
          visibility
        </span>
        <span v-else class="material-icons icon" @click="see_password = true">
          visibility_off
        </span>
      </div>
      <div class="recover-field">
        <input
          v-model="confirmPassword"
          :type="see_password ? `text` : `password`"
          placeholder="Confirmar nova senha"
        />
        <span
          v-if="see_password"
          class="material-icons icon"
          @click="see_password = false"
        >
          visibility
        </span>
        <span v-else class="material-icons icon" @click="see_password = true">
          visibility_off
        </span>
      </div>
      <Button
        :disabled="!formIsValid"
        class="buttonLogin"
        :class="{ disabled: !formIsValid }"
        @click.native="recoverPsw"
      >
        Atualizar Senha
      </Button>
    </div>
  </div>
</template>

<script>
import Button from "../components/global/GenericButton.vue";
export default {
  name: "RecoverPassword",
  components: {
    Button,
  },
  computed: {
    formIsValid() {
      return this.password && this.confirmPassword && this.email;
    },
  },
  data() {
    return {
      see_password: false,
      email: "",
      password: "",
      confirmPassword: "",
    };
  },
  methods: {
    async recoverPsw() {
      const token = location.search.split("token=")[1];
        try {
          if (this.password !== this.confirmPassword) {
            throw new Error("Suas senhas estão diferentes!");
          } else if (!token) {
            throw new Error("Você não pode atualizar a senha!");
          }
          const response = await this.$userService.recoverPassword(
            this.email,
            this.password,
            token
          );
          if (response.status === 200) {
            this.$toasted.success("Senha atualizada com sucesso!", {
              icon: "check",
              duration: 2000,
            });
            this.$router.push("/");
          }
        } catch (error) {
          console.log(error);
          this.$toasted.error(error.message, {
            icon: "error",
            duration: 3500,
          });
        }
    },
  },
};
</script>

<style scoped>
#recover-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-image: url("../assets/itasol.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  overflow: hidden;
}

#itasol-home {
font-weight: bold;
  font-size: 1.5rem;
  text-align: center;
  position: absolute;
  top: 25px;
  left: 25px;
  color: white;
  background-color: #0b245b;
  padding: .5rem;
  border-radius: .5rem;
  cursor: pointer;
}

#title-recover {
  font-weight: 600;
  font-size: 1.5rem;
  text-align: center;
  padding-bottom: 1.5rem;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #c4c4c4;
}

#recover-content {
  background-color: rgb(218, 218, 218);
  width: 500px;
  height: 480px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.recover-field {
  position: relative;
  padding: 0.5rem 0;
}

input {
  width: 80%;
  height: 2px;
  margin-bottom: 20px;
  border: none;
  font-size: 20px;
  padding: 25px;
}

input:focus {
  border: none;
}

.icon {
  font-size: 30px;
  cursor: pointer;
  display: flex;
  width: 25%;
  height: 50px;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 5px;
  right: 25px;
}

.disabled {
    background: #264586;
    cursor: not-allowed;
    color: #e5e5e5;
}

.buttonLogin {
  width: 80%;
  font-size: 20px;
  margin: 0 auto;
  margin-top: 1rem;
}
</style>