const state = {
  // lotes: [],
  createMode: false,
  nextStepCreate: false,
  selectedSwitch: "",
  coordinates: [],
  quadrasPage: [],
  selectedGleba: {},
  loaderState: false,
  current_quadra: "",
  current_lote: "",
};

const getters = {
  // getLotes(state){
  //   return state.lotes;
  // },
  getCreateMode(state) {
    return state.createMode;
  },
  getNextStepCreate(state) {
    return state.nextStepCreate;
  },
  getCoordinates(state) {
    return state.coordinates;
  },
  getSelectedSwitch(state) {
    return state.selectedSwitch;
  },
  getQuadrasPage(state) {
    return state.quadrasPage;
  },
  getSelectedGleba(state) {
    return state.selectedGleba;
  },
  getLoaderState(state) {
    return state.loaderState;
  },
  getCurrentQuadra(state) {
    return state.current_quadra;
  },
  getCurrentLote(state) {
    return state.current_lote;
  }
};

const mutations = {
  // SET_LOTES(state, value) {
  //   state.lotes = value;
  // },
  SET_CREATE_MODE(state, value) {
    state.createMode = value;
  },
  SET_NEXT_STEP_CREATE(state, value) {
    state.nextStepCreate = value;
  },
  SET_COORDINATES(state, value) {
    state.coordinates = value;
  },
  SET_SELECTED_SWITCH(state, value) {
    state.selectedSwitch = value;
  },
  SET_QUADRAS_PAGE(state, value) {
    state.quadrasPage = value;
  },
  SET_SELECTED_GLEBA(state, value) {
    state.selectedGleba = value;
  },
  SET_LOADER_STATE(state, value) {
    state.loaderState = value;
  },
  SET_CURRENT_QUADRA(state, value) {
    state.current_quadra = value;
  },
  SET_CURRENT_LOTE(state, value) {
    state.current_lote = value;
  }
};

const actions = {
  // setLotes({ commit }, value) {
  //   commit("SET_LOTES", value);
  // },
  setCreateMode({ commit }, value) {
    commit("SET_CREATE_MODE", value);
  },
  setNextStepCreate({ commit }, value) {
    commit("SET_NEXT_STEP_CREATE", value);
  },
  setCoordinates({ commit }, value) {
    commit("SET_COORDINATES", value);
  },
  setSelectedSwitch({ commit }, value) {
    commit("SET_SELECTED_SWITCH", value);
  },
  setQuadrasPage({ commit }, value) {
    commit("SET_QUADRAS_PAGE", value);
  },
  setSelectedGleba({ commit }, value) {
    commit("SET_SELECTED_GLEBA", value);
  },
  setLoaderState({ commit }, value) {
    commit("SET_LOADER_STATE", value);
  },
  setCurrentQuadra({ commit }, value) {
    commit("SET_CURRENT_QUADRA", value);
  },
  setCurrentLote({ commit }, value) {
    commit("SET_CURRENT_LOTE", value);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
