<template>
	<button>
		<slot></slot>
	</button>
</template>

<script>
export default {};
</script>

<style scoped>
button {
	cursor: pointer;
	background: #f2f2f2;
	border-radius: 5px;
	padding: 8px 14px;
	box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
	border: none;
	margin: 0px 4px;
	color: #0b245b;
	text-align: center;
	font-weight: bold;
	text-transform: uppercase;
}

button:hover {
	background: #ececec;
}

button:active {
	background: #cecece;
	box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
}

span {
	padding: 8px;
}
</style>