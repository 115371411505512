<template>
  <div id="container">
    <header
      v-bind:style="{
        backgroundImage:
          'url(https://itasolblog.files.wordpress.com/2015/12/loteamentos1.jpg?w=1400&h=882&crop=1)',
      }"
    >
      <h1>ITASOL Empreendimentos I. & M.</h1>
    </header>
    <div id="content">
      <main>
        <div id="wrapper">
          <button @click="$router.push('/')">Voltar</button>
          <h1>Loteamentos</h1>
        </div>
        <p class="main-text">
          Detentora de mais de 7 milhões de m² loteados no município de Itaboraí
          (a apenas 45 minutos da capital carioca), a Itasol conta com um
          excepcional landbank para projetos imobiliários de todos os tamanhos.
          <br />
          <br />

          Parte deste total foi loteado em diversas glebas que juntas
          correspondem aos bairros Morada do Sol I e Morada do Sol II.
          Entretanto, a empresa mantém áreas consideradas estratégicas e que
          encontram-se voltadas ao estabelecimento de projetos imobiliários.
          Neste contexto, podemos destacar as glebas 6 & 7, que juntas
          correspondem a cerca de 200 hectares e estão divididas em lotes de
          360m² devidamente matriculados. <br /><br />

          Digno de nota, a cidade – especialmente impulsionada pelas obras do
          Complexo Petroquímico do Rio de Janeiro – COMPERJ – foi positivamente
          impactada por um boom imobiliário que continua vigorando. Centros
          comerciais, universidades, bem como outros empreendimentos
          imobiliários (de menor porte) são apenas alguns dos reflexos deste
          processo.<br /><br />

          Ressalta-se ainda que com o advento da recente crise financeira que
          atinge o Brasil, muitas famílias tem abandonado os grandes centros
          urbanos em virtude dos altos custos de vida. Como consequência,
          municípios periféricos (como é o caso de Itaboraí) tem sido preteridos
          perante esta nova realidade social, resultando na constituição de um
          novo contexto imobiliário, favorecendo o surgimento de novos
          investimentos.<br /><br />

          Por isso a Itasol – especialmente por possuir lotes estrategicamente
          localizados a apenas 8 minutos do COMPERJ – mantém-se confiante no
          desenvolvimento de novos negócios na região. E busca, através de
          parceiros, implementar projetos imobiliários de médio e grande porte
          em suas áreas.<br /><br />
        </p>
      </main>
    </div>
    <footer
      v-bind:style="{
        backgroundImage:
          'url(https://itasolblog.files.wordpress.com/2015/12/cropped-cropped-cropped-bg-bemviver2.jpg)',
      }"
    >
      <p>
        2016 Itasol Empreendimentos Imobiliários & Mineração LTDA. Todos os
        direitos reservados.
      </p>
    </footer>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
#container {
  width: 100%;
  height: 100%;
  font-family: "Cinzel Regular", serif;
  background: #e6ddcd;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#content {
  width: 90%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 90%;
  height: 350px;
}

header > h1 {
  font-weight: 400;
  font-size: 1.5rem;
  z-index: 10;
  color: #fcfbf9;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  text-shadow: 0.1em 0.1em 0.2em black;
}

/* Main */
main {
  background-color: white;
  width: 60%;
}

main > img {
  width: 100%;
  height: auto;
  margin: 2rem 0;
}

#wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 3rem;
}

#wrapper > button {
  font-family: "Quattrocento Regular", sans-serif;
  background-color: transparent;
  text-transform: uppercase;
  color: #a9a9a9;
  font-weight: 700;
  font-size: 1rem;
  transition: all 0.2s ease-in-out;
  margin: 3rem 0 0.5rem 0;
}

#wrapper > button:hover {
  cursor: pointer;
  filter: brightness(0.8);
}

#wrapper > h1 {
  font-size: 2.5rem;
  font-family: "Cinzel Regular", serif;
  text-transform: uppercase;
  font-weight: 400;
  color: #2a6263;
  /* margin-left: 0.2rem; */
  margin-top: 0.5rem;
}

.main-text {
  font-family: "Quattrocento Regular", sans-serif;
  color: #726b60;
  font-weight: 400;
  font-size: 1.125rem;
  text-align: justify;
  line-height: 1.7rem;
  padding-bottom: 3rem;
}

/* Footer */
footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 90%;
  /* filter: brightness(0.4); */
}

footer > p {
  padding: 1rem 1rem;
  color: #fcfbf9;
}

@media screen and (min-width: 480px) and (max-width: 768px) {
  header > h1 {
    font-size: 1.2rem;
    margin: 0 1rem;
  }
  main {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #wrapper > h1 {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  header > h1 {
    font-size: 1.2rem;
    margin: 0 1rem;
  }

  main {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #wrapper > h1 {
    font-size: 1.8rem;
  }
}
</style>