<template>
  <div class="container" @click.stop>
    <div class="lateral-bar">
      <div class="content">
        <Bar v-model="filter" id="search-bar" @input="setFilter" />
        <Pagination :items="glebasList" />
        <div
          class="cards"
          v-if="listLotes.length > 0 && selectedSwitch === `lotes`"
        >
          <div v-for="(lote, index) in listLotes" :key="lote.fid" class="cardc">
            <Card
              cardType="lote"
              :lote="lote"
              :sit="lote.sit2"
              :edit="edit"
              :editmode="editmode"
              @onEdit="edit = true"
              @onSelect="selectLote(lote.id)"
              @onUnselect="unselectLote(lote.id)"
              @onCancel="edit = false"
              @onSave="handlerEdit"
              @onDelete="handlerDialog(lote.id, index, lote.fid)"
            />
          </div>
        </div>
        <div
          class="cards"
          v-else-if="quadras.length > 0 && selectedSwitch === `glebas`"
        >
          <div v-for="(quadra, index) in quadras" :key="index" class="cardc">
            <Card
              cardType="gleba"
              :gleba="quadra"
              @filterLotesGleba="filterLotesGleba"
              @setAllLotes="$emit('setAllLotes')"
            />
          </div>
        </div>
        <div class="cards" v-else>
          <h1 class="empty-data">Nenhum dado de lote encontrado!</h1>
        </div>
      </div>
    </div>
    <Dialog
      v-show="config"
      fullscreen
      title="Deletar Lote"
      :text="`Deseja mesmo excluir o lote ${selected.fid}?`"
      @confirm="handlerDelete(selected)"
      @cancel="config = false"
      @close="config = false"
      :options="options"
    />
    <!-- <Dialog
      title="Configurações"
      :text="`Deseja mesmo excluir o lote ${selected.id}?`"
      @onSave="handlerDelete(selected)"
      @onCancel="config = false"
    /> -->
  </div>
</template>

<script>
import Bar from "./Bar";
import Card from "./Card";
import Dialog from "../global/Dialog";
import Pagination from "./Pagination.vue";
export default {
  created() {
    // console.log('***LateralBar: ', this.glebasList);
    this.createList(this.loteamento);
    this.createListQuadras(this.selectedGleba.quadras);
  },
  components: {
    Bar,
    Card,
    Dialog,
    Pagination,
  },
  watch: {
    selectedGleba() {
      this.createListQuadras(this.selectedGleba.quadras);
    },
    loteamento: function () {
      // console.log("yeahh baby");
      this.createList(this.loteamento);
    },
    selectedLotsIds: function () {
      this.$emit("onSelectLots", this.selectedLotsIds);
    },
  },
  data() {
    return {
      selected: false,
      config: false,
      filter: { color: "", search: "" },
      edit: false,
      lotes: [],
      choiceColor: "",
      selectedLotsIds: [],
      options: [
        { label: "Confirmar", event: "confirm" },
        { label: "Cancelar", event: "cancel" },
      ],
    };
  },
  props: {
    loteamento: Array,
    glebasList: Array,
    editmode: {
      required: true,
    },
  },
  methods: {
    filterLotesGleba(gleba) {
      this.$emit("filterLotesGleba", gleba);
    },
    setFilter(payload) {
      this.filter = payload;
    },
    createListQuadras: function (value) {
      var aux = [];
      Object.keys(value).forEach(function (item) {
        aux.push({
          // id: value[item]._id,
          quadra: value[item].quadra,
          glebaData: [
            {
              label: "N° lotes",
              value: value[item].num_lotes,
              key: "num_lotes",
            },
            {
              label: "Lotes especiais",
              value: value[item].lotes_especiais,
              key: "lotes_especiais",
            },
            {
              label: "Verificação",
              value: value[item].verif,
              key: "verif",
            },
            {
              label: "Área total (m2)",
              value: value[item].area_total,
              key: "area_total",
            },
          ],
        });
      });
      this.$store.dispatch("mapa/setQuadrasPage", aux);
    },
    createList: function (value) {
      var aux = [];
      Object.keys(value).forEach(function (item) {
        aux.push({
          id: value[item]._id,
          fid: value[item].properties.fid,
          qtd: parseInt(value[item].properties.fid),
          color: value[item].properties.sit2,
          observation: value[item].observation,
          loteData: [
            { label: "Lote", value: value[item].properties.lote, key: "lote" },
            {
              label: "Quadra",
              value: value[item].properties.quadra,
              key: "quadra",
            },
            {
              label: "Gleba",
              value: value[item].properties.gleba,
              key: "gleba",
            },
            {
              label: "Área(m2)",
              value: value[item].properties.area,
              key: "area",
            },
            {
              label: "Situação",
              value: value[item].properties.sit2,
              key: "situacao",
            },
            {
              label: "Plano",
              value: value[item].properties.plano,
              key: "plano",
            },
            { label: "Gab", value: value[item].properties.gab, key: "gab" },
            {
              label: "Custo",
              value: value[item].properties.custo,
              key: "custo",
            },
            {
              label: "Cód. Comprador",
              value: value[item].properties.cod_comprador,
              key: "cod_comprador",
            },
            {
              label: "Nome Comprador",
              value: value[item].properties.nome_comprador,
              key: "nome_comprador",
            },
          ],
        });
      });
      this.lotes = aux;
    },
    onSave: function () {
      const token = localStorage.getItem("token");
      const config = { headers: { Authorization: `Bearer ${token}` } };
      for (var i = 0; i < this.loteSave.length; i++) {
        this.$http
          .put(
            "loteamentos/update/" + this.loteSave[i].id,
            this.loteSave[i],
            config
          )
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    handlerEdit(lote) {
      // console.log('***LatelBar/handlerEdit: ', lote)
      // PUT method
      // const token = localStorage.getItem('token')
      // const config = { headers: { Authorization: `Bearer ${token}` } }
      //Obtem loteamentos
      // this.$http
      //   .put('loteamentos/update/' + lote.id, lote, config)
      //   .then((res) => {
      //     console.log(res)
      //     this.$emit('reload')
      //   })
      //   .catch((err) => {
      //     console.log(err)
      //   })

      this.$fileService
        .updateLote(lote)
        .then(() => {
          // console.log('***LateralBar: ', lote);
          // let lotesUpdated = this.$loteService.updateLoteamento(
          //   lote,
          //   this.loteamento
          // );
          // this.$store.dispatch("mapa/setLotes", lotesUpdated);
          this.$emit("reload");
          // console.log('***LatelBar/handlerEdit: ', res.data);
          this.$toasted.success("Lote editado com sucesso!", {
            icon: "check",
            duration: 2000,
          });
        })
        .catch((err) => {
          console.log(err);
          this.$toasted.error(err.message, {
            icon: "error_outline",
            duration: 2000,
          });
        });
      this.edit = false;
    },
    selectLote(id) {
      this.selectedLotsIds.push(id);
    },
    unselectLote(id) {
      this.selectedLotsIds = this.selectedLotsIds.filter((v) => v !== id);
    },
    handlerDelete({ id, index }) {
      // DELETE method
      const token = localStorage.getItem("token");
      const config = { headers: { Authorization: `Bearer ${token}` } };
      this.$http
        .delete("loteamentos/delete/" + id, config)
        .then((res) => {
          if (res.status == 200) {
            this.$toasted.success("Lotes deletados com sucesso!", {
              icon: "check",
              duration: 2000,
            });
            this.$emit("reload");
          }
        })
        .catch((err) => {
          this.$toasted.error(err.message, {
            icon: "error_outline",
            duration: 2000,
          });
        });
      this.lotes.splice(index, 1);
      // let lotesDeleted = this.$loteService.deleteLoteamento(
      //   id,
      //   this.loteamento
      // );
      // this.$store.dispatch("mapa/setLotes", lotesDeleted);
      //Close all
      this.config = false;
      this.selected = false;
      this.edit = false;
    },
    handlerDialog(id, index, fid) {
      this.config = true;
      this.selected = { id, index, fid };
    },
    handlerCancel() {
      this.config = false;
      this.selected = false;
      this.edit = false;
    },
    obtemCor: function (value) {
      switch (value) {
        case "QUITADO":
          return "#00a";
        case "ESCRITURA":
          return "#fff";
        case "ATRASADO":
          return "#a00";
        case "EM DIA":
          return "#0a0";
        case "ESTOQUE":
          return "#aaa";
        case "RESERVADO":
          return "#aa2";
        default:
          return "#d91";
      }
    },
    procura: function (array) {
      let search = this.filter.search.toLowerCase() || "";

      var result = array.filter((result) => {
        return result.value?.toString().toLowerCase().includes(search);
      });

      return result.length > 0 ? true : false;
    },
  },
  computed: {
    selectedGleba() {
      return this.$store.getters["mapa/getSelectedGleba"];
    },
    quadras() {
      return this.$store.getters["mapa/getQuadrasPage"];
    },
    selectedSwitch() {
      return this.$store.getters["mapa/getSelectedSwitch"];
    },

    listLotes: function () {
      let search = this.filter.search.toLowerCase() || "";
      let color = this.filter.color || "";

      // console.log('***LateralBar: ', search.length, color.length);

      if (search.length > 0 && color.length > 0) {
        return this.lotes.filter(
          (lote) =>
            (lote.observation.toLowerCase().includes(search) ||
              this.procura(lote.loteData) ||
              lote.fid.toString().toLowerCase().includes(search)) &&
            lote.color === this.filter.color
        );
      } else if (search.length > 0 && color.length === 0) {
        return this.lotes.filter(
          (lote) =>
            lote.observation.toLowerCase().includes(search) ||
            this.procura(lote.loteData) ||
            lote.fid.toString().toLowerCase().includes(search)
        );
      } else if (search.length === 0 && color.length > 0) {
        return this.lotes.filter((lote) => lote.color === this.filter.color);
      } else {
        return this.lotes;
      }

      // if (
      //   (this.filter.search != "" || this.filter.search != " ") &&
      //   (this.filter.color != "" || this.filter.color != " ")
      // ) {
      //   return this.lotes.filter(
      //     (lote) =>
      //       (lote.observation.toLowerCase().includes(search) ||
      //       this.procura(lote.loteData) || lote.fid.toString().toLowerCase().includes(search))
      //   );
      // } else if (
      //   (this.filter.color != "" || this.filter.color != " ") &&
      //   (this.filter.search == "" || this.filter.search == " ")
      // ) {
      //   // console.log("==>",this.lotes.filter((lote) => lote.color === this.filter.color));
      //   return this.lotes.filter((lote) => lote.color === this.filter.color);
      // } else {
      //   return this.lotes;
      // }
    },
  },
};
</script>

<style scoped>
.lateral-bar {
  width: 340px;
  position: relative;
  z-index: 4;
  background: transparent;
}
.content {
  display: flex;
  max-width: 340px;
  height: 100vh;
  flex-direction: column;
  overflow: auto;
  max-height: 100vh;
  overflow-x: hidden;
  background: #f2f2f2;
}
.cards {
  padding: 13px 13px;
  overflow: auto;
  background: #f2f2f2;
}

.empty-data {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  margin: 100% auto;
}

.cardc {
  position: relative;
  width: 300px;
}
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  background: #e6e6e6;
}
::-webkit-scrollbar-thumb {
  background: #c5c5c5;
  border-radius: 4px;
}
@media screen and (min-width: 480px) and (max-width: 768px) {
  .lateral-bar {
    width: 300px;
  }
  .content {
    width: 300px;
  }
  .cardc {
    width: 260px;
  }
  #search-bar {
    width: 300px;
  }
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  .lateral-bar {
    width: 280px;
  }
  .content {
    width: 280px;
  }
  .cardc {
    width: 250px;
  }
  #search-bar {
    width: 280px;
  }
}
</style>
