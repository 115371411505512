<template>
  <div id="pagination-container">
    <div class="content-btn">
      <h2
        class="btn-select"
        @click="setValue('lotes')"
        :class="{ active: selected === `lotes` }"
      >
        Lotes
      </h2>
      <h2
        class="btn-select"
        @click="setValue('glebas')"
        :class="{ active: selected === `glebas` }"
      >
        Glebas
      </h2>
    </div>
    <GlebasList :items="items" v-if="selected === `glebas`" />
    <!-- <Paginate
      v-if="selected === `glebas`"
      :items="items"
      @changePage="onChangeGleba"
      :disabledLabels="true"
    /> -->
  </div>
</template>

<script>
// import Paginate from "../global/Paginate.vue";
import GlebasList from "./GlebasList.vue";
export default {
  components: {
    GlebasList,
    // Paginate
  },
  created() {
    // console.log('***Pagination: ', this.items);
    this.setValue("lotes");
  },
  props: {
    items: {
      Array,
    },
  },
  computed: {
    selected() {
      return this.$store.getters["mapa/getSelectedSwitch"];
    },
  },
  methods: {
    setValue(value) {
      this.$store.dispatch("mapa/setSelectedSwitch", value);
    },
  },
};
</script>

<style scoped>
.content-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1.2rem 0;
}
.btn-select {
  margin-left: 0.8rem;
  font-size: 1.1rem;
  font-weight: 600;
  padding: 0.5rem;
  transition: all 0.2s ease-in;
}

.btn-select:hover {
  background: #e2e2e2;
  border-radius: 0.5rem;
}

.active {
  background: #c4c4c4;
  border-radius: 0.5rem;
}

.active:hover {
  background: #c4c4c4;
  border-radius: 0.5rem;
}
</style>
