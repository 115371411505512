<template>
  <div class="modal" :class="fullscreen ? 'fullscreen' : ''">
    <div class="modal-body">
      <div class="title">
        <h2>{{ title }}</h2>
        <h2 @click="handlerCancel">X</h2>
      </div>

      <p v-if="text">{{ text }}</p>
      <slot></slot>
      <template v-for="option in options">
        <span :key="option.label" @click="handlerEvent(option.event)">
          {{ option.label }}
        </span>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: Array,
    title: String,
    text: String,
    fullscreen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    log() {
      console.log("clicked");
    },
    handlerEvent(event) {
      this.$emit(event);
      this.$emit("close");
    },
    handlerSave() {
      this.$emit("onSave");
      this.$emit("close");
    },
    handlerCancel() {
      this.$emit("onCancel");
      this.$emit("close");
    },
    handlerClose() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.fullscreen {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-body {
  width: 320px;
  padding: 16px;
  background: #f2f2f2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 5px 5px #999;
}
.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
/* .close-button {
  cursor: pointer;
  font-size: 24px;
  padding: 4px;
} */
p {
  padding: 4px 4px;
  line-height: 24px;
}
span {
  margin: 4px 0px;
  background-color: #d2d2d2;
  padding: 16px 0px;
  cursor: pointer;
}
span:hover {
  background-color: #ccc;
}
span:active {
  background-color: #bbb;
}
h2 {
  font-weight: bold;
  font-size: 24px;
  color: #0b245b;
}
.mt-8 {
  margin-top: 8px;
}
@media screen and (min-width: 480px) and (max-width: 768px) {
  .modal-body {
    width: 200px;
  }
  .close-button {
    font-size: 20px;
    padding: 4px;
    margin-left: 5px;
  }
  h2 {
    font-size: 20px;
  }
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  .modal-body {
    width: 300px;
    overflow-y: scroll;
    height: 95%;
  }
  .close-button {
    font-size: 20px;
    padding: 4px;
    margin-left: 5px;
  }
  h2 {
    font-size: 20px;
  }
}
</style>
