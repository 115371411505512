import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
// import Teste from "../views/Teste.vue";
import Login from "../views/Login.vue";
import NotFound from "../views/404.vue";
import RecoverPassword from "../views/RecoverPassword.vue";
import Page from "../views/landing-page/Page.vue";
import store from "../store/index";
import About from "../views/landing-page/About.vue";
import Loteamentos from "../views/landing-page/Loteamentos.vue";
import Contato from "../views/landing-page/Contato.vue";
import Mineracao from "../views/landing-page/Mineracao.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Page",
    // mode: history,
    component: Page,
    meta: {
      auth: false,
      isLandingPage: true,
    },
  },
  {
    path: "/sobre",
    name: "About",
    // mode: history,
    component: About,
    meta: {
      auth: false,
      isLandingPage: true,
    },
  },
  {
    path: "/loteamentos",
    name: "Loteamentos",
    // mode: history,
    component: Loteamentos,
    meta: {
      auth: false,
      isLandingPage: true,
    },
  },
  {
    path: "/contato",
    name: "Contato",
    component: Contato,
    meta: {
      auth: false,
      isLandingPage: true,
    },
  },
  {
    path: "/mineracao",
    name: "Mineração",
    component: Mineracao,
    meta: {
      auth: false,
      isLandingPage: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      auth: false,
      isLandingPage: false,
    },
  },
  {
    path: "/users/change-password/",
    name: "RecoverPassword",
    component: RecoverPassword,
    meta: {
      auth: false,
      isLandingPage: false,
    },
  },
  // {
  //   path: "/teste",
  //   name: "Teste",
  //   component: Teste,
  //   meta: {
  //     auth: true,
  //   },
  // },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      auth: true,
      isLandingPage: false,
    },
  },
  {
    path: "/admin/users",
    name: "users-admin",
    component: () => import("@/views/admin/Users"),
    meta: {
      permission: "admin",
      auth: true,
      isLandingPage: false,
    },
  },
  {
    path: "/admin/files",
    name: "files-admin",
    component: () => import("@/views/admin/Files"),
    meta: {
      permission: "admin",
      auth: true,
      isLandingPage: false,
    },
  },
  {
    path: "/404",
    name: "404",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => {
    if (record.meta?.auth === true) {
      return true;
    } else return false;
  });

  const isLandingPage = to.matched.some((record) => {
    return record.meta?.isLandingPage;
  });

  const isAuth = store.getters["auth/getUser"];
  const userPermission = store.getters["auth/getPermissionLevel"];

  const permissions = {
    admin: 0,
    mod: 1,
    user: 2,
  };

  const hasPermission = to.matched.some((record) => {
    if (record.meta?.permission) {
      if (
        userPermission === permissions[record.meta.permission] ||
        userPermission < permissions[record.meta.permission]
      ) {
        return true;
      }
      return false;
    } else return true;
  });

  if (isLandingPage) {
    next();
  } else if (requiresAuth && !isAuth) {
    next("/");
  } else if (!hasPermission) {
    next("/404");
  } else if (!requiresAuth && isAuth) {
    // Impede de entrar em login, já estando logado
    next("/home");
  } else {
    next();
  }
});

export default router;
