<template>
  <div id="app">
    <loader
      v-if="loader"
      object="#25304C"
      color1="#ffffff"
      color2="#17fd3d"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="80"
      name="circular"
    ></loader>
    <router-view />
  </div>
</template>

<script>
export default {
  computed: {
    loader() {
      return this.$store.getters['mapa/getLoaderState'];
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

* {
  box-sizing: border-box;
  outline: none;
  border: none;
  font-family: sans-serif;
}
</style>
