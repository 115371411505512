<template>
  <div id="map"></div>
</template>

<script>
import L from "leaflet";
import "leaflet-draw";
export default {
  name: "Mapa",
  mounted() {
    this.$store.dispatch("mapa/setCreateMode", false);
    this.$store.dispatch("mapa/setNextStepCreate", false);
    this.setupLeafletMap();
    this.addLote();
    this.addAcessos();
    this.addLinhao();
    this.addArruamento();
    // console.log("mounted: ", this.loteamento);
  },
  data() {
    return {
      editable_layers: "",
      layer_lote: L.layerGroup([]),
      draw_control: "",
      mapDiv: "",
      layer_id: "",
      //Dados do layer basico
      layer_url:
        "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
      layer_attribution:
        '<a href="http://megajunior.com.br" target="_blank" title="Mega Junior">Mega Junior</a> | <a href="http://www.azimutejr.com/" target="_blank" alt="Azimute Engenharia Júnior" title="Azimute Engenharia Júnior">Azimute Engenharia Júnior</a>',
      layer_id_basic: "mapbox/streets-v11",
      token:
        "pk.eyJ1IjoibWVnYWpyIiwiYSI6ImNrcDB1cTNjZjAyajYycW50ODkwM2gxNjAifQ.XZEAQtIlcjqVLpywhKzI3A",
      //Dados mapa satelite
      layer_id_satelite: "mapbox/satellite-v9",
      layer_id_3d: "mapbox/outdoors-v11",
      zoom: 0,
    };
  },
  props: {
    loteamento: Array,
    linhao: Array,
    arruamento: Array,
    arruamento_aux: Array,
    acessos: Array,
  },
  computed: {
    createMode() {
      return this.$store.getters["mapa/getCreateMode"];
    },
    nextStepCreate() {
      return this.$store.getters["mapa/getNextStepCreate"];
    },
  },
  watch: {
    createMode: function (newValue) {
      if (newValue) {
        this.addDrawOptions();
      } else {
        this.removeDrawOptions();
      }
    },
    loteamento: function () {
      this.addLote();
    },
    acessos: function () {
      this.addAcessos();
    },
    linhao: function () {
      this.addLinhao();
    },
    arruamento: function () {
      this.addArruamento();
    },
    arruamento_aux: function () {
      this.addArruamento();
    },
  },
  methods: {
    //Constroi mapa
    setupLeafletMap: function () {
      this.mapDiv = L.map("map", {
        zoomControl: false,
        maxZoom: 18,
        minZoom: 7,
      }).fitBounds([
        [-22.75570019783711, -42.942498514639595],
        [-22.72615473199701, -42.899968143380605],
      ]);
      this.zoom = this.mapDiv.getZoom();
      this.layer_id = this.layer_id_basic;
      L.tileLayer(this.layer_url, {
        attribution: this.layer_attribution,
        maxZoom: 18,
        id: this.layer_id,
        accessToken: this.token,
      }).addTo(this.mapDiv);
    },

    //Seta o mapa editavel
    addDrawOptions: function () {
      var editableLayers = new L.FeatureGroup();
      this.editable_layers = editableLayers;
      this.mapDiv.addLayer(editableLayers);

      var options = {
        position: "topright",
        draw: {
          polyline: false,
          polygon: {
            allowIntersection: false, // Restricts shapes to simple polygons
            drawError: {
              color: "#e1e100", // Color the shape will turn when intersects
              message:
                "<strong>Oh desculpe!<strong> você não pode desenhar assim!", // Message that will show when intersect
            },
          },
          // circle: true, // Turns off this drawing tool
          rectangle: true,
          marker: false,
        },
        edit: {
          featureGroup: editableLayers, //REQUIRED!!
          // remove: true,
        },
      };

      var drawControl = new L.Control.Draw(options);
      this.draw_control = drawControl;

      var store = this.$store;

      this.mapDiv.addControl(drawControl);

      this.mapDiv.on(L.Draw.Event.CREATED, function (e) {
        var type = e.layerType,
          layer = e.layer;

        // console.log("layer: ", e.layer._latlngs);

        if (type === "marker") {
          layer.bindPopup("A popup!");
        }

        editableLayers.addLayer(layer);
        store.dispatch("mapa/setCoordinates", e.layer._latlngs);
        store.dispatch("mapa/setNextStepCreate", true);
        store.dispatch("mapa/setCreateMode", false);
      });
    },

    //Remover mapa editavel
    removeDrawOptions: function () {
      if (this.editable_layers) {
        this.mapDiv.removeLayer(this.editable_layers);
      }

      if (this.draw_control) {
        this.mapDiv.removeControl(this.draw_control);
      }
    },

    //Adiciona os acessos
    addAcessos: function () {
      L.geoJson(this.acessos, {
        name: "acessos",
        style: {
          weight: 3,
          color: "#a0c",
          dashArray: "1,5",
          lineCap: "square",
          lineJoin: "bevel",
          zIndex: 401,
          interactive: false,
        },
      }).addTo(this.mapDiv);
    },

    //adiciona o arruamento
    addArruamento: function () {
      L.layerGroup([
        L.geoJson(this.arruamento, {
          onEachFeature: function (feature, layer) {
            layer.on({
              mouseout: function (e) {
                for (var i in e.target._eventParents) {
                  e.target._eventParents[i].resetStyle(e.target);
                }
              },
              mouseover: function (e) {
                e.target.setStyle({ opacity: 1 });
              },
            });
            layer.bindTooltip(
              "<table> <tr> <td colspan='2'><strong>Nome: </strong>" +
                (feature.properties["nome"] !== null)
                ? feature.properties["nome"]
                : " " + "</td> </tr> </table>"
            );
          },
          style: {
            weight: 5, //2
            color: "#ff0",
            opacity: 0,
            dashArray: "7,5",
            lineCap: "square",
            lineJoin: "bevel",
            zIndex: 402,
          },
        }),
        L.geoJson(this.arruamento_aux, {
          style: {
            weight: 0.3,
            color: "#333",
            fillColor: "#aaa",
            fillOpacity: 1,
            zIndex: 401,
            interactive: false,
          },
        }),
      ]).addTo(this.mapDiv);
    },

    //Adiciona os linhões no mapa
    addLinhao: function () {
      L.geoJson(this.linhao, {
        name: "linhao",
        style: {
          weight: 0,
          fillColor: "#0ff",
          fillOpacity: 0.5,
          interactive: false,
        },
      }).addTo(this.mapDiv);
    },

    //Adiciona os loteamentos
    addLote: function () {
      this.mapDiv.removeLayer(this.layer_lote);
      this.layer_lote = L.geoJson(this.loteamento, {
        name: "loteamento",
        onEachFeature: function (feature, layer) {
          layer.on({
            mouseout: function (e) {
              for (var i in e.target._eventParents) {
                e.target._eventParents[i].resetStyle(e.target);
              }
            },
            mouseover: function (e) {
              e.target.setStyle({ opacity: 1 });
            },
            click: function () {
              // console.log("***Components/Mapa", feature);
              layer.bindTooltip(
                "<table> <tr align='left'> <td colspan='2'> <strong>Situação: </strong>" +
                  (feature.properties.sit2 !== null
                    ? `${feature.properties.sit2}`
                    : "Indefinido") +
                  " </td></tr> <tr align='left'> <td colspan='2'> <strong>Lote: </strong>" +
                  (feature.properties.lote !== null
                    ? `${feature.properties.lote}`
                    : "-") +
                  " </td></tr><tr align='left'> <td colspan='2'> <strong>Quadra: </strong>" +
                  (feature.properties.quadra !== null
                    ? `${feature.properties.quadra}`
                    : "-") +
                  " </td></tr><tr align='left'> <td colspan='2'> <strong>Gleba: </strong>" +
                  (feature.properties.gleba !== null
                    ? `${feature.properties.gleba}`
                    : "-") +
                  " </td></tr><tr align='left'> <td colspan='2'> <strong>Area(m2): </strong>" +
                  (feature.properties.area !== null
                    ? `${feature.properties.area}`
                    : "-") +
                  " </td></tr><tr align='left'> <td colspan='2'> <strong>Plano: </strong>" +
                  (feature.properties.plano !== null
                    ? `${feature.properties.plano}`
                    : "-") +
                  " </td></tr><tr align='left'> <td colspan='2'> <strong>Gab: </strong>" +
                  (feature.properties.gab !== null
                    ? `${feature.properties.gab}`
                    : "-") +
                  " </td></tr><tr align='left'> <td colspan='2'> <strong>Custo: </strong>" +
                  (feature.properties.custo !== null
                    ? `${feature.properties.custo}`
                    : "-") +
                  " </td></tr><tr align='left'> <td colspan='2'> <strong>Cód. Comprador: </strong>" +
                  (feature.properties.cod_comprador !== null
                    ? `${feature.properties.cod_comprador}`
                    : "-") +
                  " </td></tr><tr align='left'> <td colspan='2'> <strong>Nome Comprador: </strong>" +
                  (feature.properties.nome_comprador !== null
                    ? `${feature.properties.nome_comprador}`
                    : "-") +
                  " </td></tr></table>"
              );
            },
          });
          layer.bindTooltip(
            "<table> <tr align='left'> <td colspan='2'> <strong>Situação: </strong>" +
              (feature.properties.sit2 !== null
                ? `${feature.properties.sit2}`
                : "Indefinido") +
              " </td></tr> <tr align='left'> <td colspan='2'> <strong>Lote: </strong>" +
              (feature.properties.lote !== null
                ? `${feature.properties.lote}`
                : "-") +
              " </td></tr><tr align='left'> <td colspan='2'> <strong>Quadra: </strong>" +
              (feature.properties.quadra !== null
                ? `${feature.properties.quadra}`
                : "-") +
              " </td></tr><tr align='left'> <td colspan='2'> <strong>Gleba: </strong>" +
              (feature.properties.gleba !== null
                ? `${feature.properties.gleba}`
                : "-") +
              " </td></tr><tr align='left'> <td colspan='2'> <strong>Area(m2): </strong>" +
              (feature.properties.area !== null
                ? `${feature.properties.area}`
                : "-") +
              " </td></tr><tr align='left'> <td colspan='2'> <strong>Plano: </strong>" +
              (feature.properties.plano !== null
                ? `${feature.properties.plano}`
                : "-") +
              " </td></tr><tr align='left'> <td colspan='2'> <strong>Gab: </strong>" +
              (feature.properties.gab !== null
                ? `${feature.properties.gab}`
                : "-") +
              " </td></tr><tr align='left'> <td colspan='2'> <strong>Custo: </strong>" +
              (feature.properties.custo !== null
                ? `${feature.properties.custo}`
                : "-") +
              " </td></tr><tr align='left'> <td colspan='2'> <strong>Cód. Comprador: </strong>" +
              (feature.properties.cod_comprador !== null
                ? `${feature.properties.cod_comprador}`
                : "-") +
              " </td></tr><tr align='left'> <td colspan='2'> <strong>Nome Comprador: </strong>" +
              (feature.properties.nome_comprador !== null
                ? `${feature.properties.nome_comprador}`
                : "-") +
              " </td></tr></table>"
          );
        },
        style: function (feature) {
          switch (feature.properties.sit2) {
            case "QUITADO":
              return {
                color: "#00a",
                weight: 1,
                fillopacity: 1,
                fillColor: "#00a",
                interactive: true,
              };
            case "ESCRITURA":
              return {
                color: "#fff",
                weight: 1,
                fillopacity: 1,
                fillColor: "#fff",
                interactive: true,
              };
            case "ATRASADO":
              return {
                color: "#a00",
                weight: 1,
                fillopacity: 1,
                fillColor: "#a00",
                interactive: true,
              };
            case "EM DIA":
              return {
                color: "#0a0",
                weight: 1,
                fillopacity: 1,
                fillColor: "#0a0",
                interactive: true,
              };
            case "ESTOQUE":
              return {
                color: "#aaa",
                weight: 1,
                fillopacity: 1,
                fillColor: "#aaa",
                interactive: true,
              };
            case "RESERVADO":
              return {
                color: "#aa2",
                weight: 1,
                fillopacity: 1,
                fillColor: "#aa2",
                interactive: true,
              };
            default:
              return {
                color: "#d91",
                weight: 1,
                fillopacity: 1,
                fillColor: "#d91",
                interactive: true,
              };
          }
        },
      }).addTo(this.mapDiv);
    },

    //Mudar de mapa
    switchMap: function () {
      L.tileLayer(this.layer_url, {
        attribution: this.layer_attribution,
        maxZoom: 18,
        id: this.layer_id,
        accessToken: this.token,
      }).addTo(this.mapDiv);
    },

    satelite: function () {
      console.log(this.layer_id);
      if (this.layer_id == this.layer_id_satelite) {
        this.layer_id = this.layer_id_basic;
      } else {
        this.layer_id = this.layer_id_satelite;
      }
      this.switchMap();
    },

    //Insere mapa 3D
    local: function () {
      if (this.layer_id == this.layer_id_3d) {
        this.layer_id = this.layer_id_basic;
      } else {
        this.layer_id = this.layer_id_3d;
      }
      this.switchMap();
    },

    //Serve para o botão de zoom plus
    zoomplus: function () {
      this.zoom = this.zoom + 0.5;
      this.mapDiv.setZoom(this.zoom);
    },

    //Serve para o botão zoom menos
    zoommine: function () {
      this.zoom = this.zoom - 0.5;
      this.mapDiv.setZoom(this.zoom);
    },

    getLocation: function () {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.showPosition);
      } else {
        alert("O seu navegador não suporta Geolocalização.");
      }
    },

    //Mostra localização atual
    showPosition: function (position) {
      this.mapDiv.setView(
        new L.LatLng(position.coords.latitude, position.coords.longitude),
        15
      );
      L.marker([position.coords.latitude, position.coords.longitude], {
        icon: L.icon({
          iconUrl: "../assets/icons/placeholder.png",
          iconSize: [38, 95],
          iconAnchor: [22, 94],
          popupAnchor: [-3, -76],
        }),
      }).addTo(this.mapDiv);
    },

    //Retorna a localização dos lotes
    home: function () {
      this.mapDiv.fitBounds([
        [-22.75570019783711, -42.942498514639595],
        [-22.72615473199701, -42.899968143380605],
      ]);
    },
  },
};
</script>

<style>
#map {
  width: 100vw;
  height: 100vh;
  z-index: 0;
}

button {
  float: right;
}
</style>
