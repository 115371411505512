<template>
  <div>
    <div class="mapa">
      <Mobilebar id="mobile-bar" @openLateralBar="handleShowSideBar" />
      <LateralBar
        :class="
          showLateralBarMobile ? `lateral-bar-mobile` : `lateral-bar-desktop`
        "
        :loteamento="loteamentos"
        :glebasList="glebas"
        :editmode="editmode"
        @onSelectLots="selectedLots = $event"
        @reload="loadLotes"
        @filterLotesGleba="filterLotesGleba"
        @setAllLotes="setAllLotes"
        v-click-outside="handleBarOutside"
      />
      <!-- <LateralBar
				:loteamento="this.listLotes"
				:edit="this.edit"
				:editmode="editmode"
				:remove="this.remove"
				@filter="filtrar"
				@choiceColor="filtercolor"
				@editar="habilitarEdit"
				@cancel="cancel"
				@onSelectLots="selectedLots = $event"
				@reload="loadLotes"
				ref="lateralBar"
			/> -->
      <Mapa
        :loteamento="this.loteamentos"
        :linhao="this.linhao"
        :arruamento="this.arruamentos"
        :arruamento_aux="this.arruamento_aux"
        :acessos="this.acessos"
        @filter="filtrar"
        ref="map"
      />
      <NavigationBar
        @zoomplus="zoomplus"
        @zoommine="zoommine"
        @geoloc="geoloc"
        @satelite="satelite"
        @local="local"
        @home="home"
      />
      <OptionsBar
        :loteamento="loteamentos"
        :editmode="editmode"
        @reload="loadLotes"
        @changeMode="editmode = !editmode"
        @onDeleteLots="deleteLotes()"
        v-show="nextStepCreate || !createMode"
      />
      <div id="cancel-create-mode" v-show="createMode">
        <span class="material-icons-outlined" @click="onCancelCreate">
          close
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import LateralBar from "../components/LateralBar/LateralBar";
import NavigationBar from "../components/NavigationBar/NavigationBar";
import OptionsBar from "../components/NavigationBar/OptionsBar";
import Mapa from "../components/Mapa";
import Mobilebar from "../components/NavigationBar/MobileBar";
// import { glebasList } from "../utils/glebas";
// eslint-disable-next-line
// import { loteamentos } from '../utils/loteamento';
// import { arruamentos } from '../utils/arruamento';
// import { arruamentoAux } from '../utils/arruamento-aux';
// import { linhao } from '../utils/linhao';

export default {
  name: "Home",
  components: {
    LateralBar,
    NavigationBar,
    Mapa,
    OptionsBar,
    Mobilebar,
  },
  data() {
    return {
      showLateralBarMobile: false,
      loteamentos: [],
      loteamentosAux: [],
      glebas: [],
      arruamentos: [],
      acessos: [],
      arruamento_aux: [],
      linhao: [],
      token: "",
      filter: "",
      selectedLots: [],
      choiceColor: "",
      edit: false,
      add: false,
      remove: false,
      editmode: false,
    };
  },
  created() {
    const token = localStorage.getItem("token");
    const config = { headers: { Authorization: `Bearer ${token}` } };
    // Inicia o loading dos dados
    // this.$store.dispatch("mapa/setLoaderState", true);
    // Obtem os lotes
    this.loadLotes();
    // Obtem as glebas
    this.loadGlebas();
    //Obtem Arruamentos
    this.$http
      .get("arruamentos/list", config)
      .then((res) => {
        this.arruamentos = res.data.arruamentos;
      })
      .catch((err) => {
        console.log(err);
      });
    // this.arruamentos = arruamentos.features;
    //Obtem acessos
    this.$http
      .get("acessos/list", config)
      .then((res) => {
        this.acessos = res.data.acessos;
      })
      .catch((err) => {
        console.log(err);
      });
    //Obter linhao
    this.$http
      .get("linhao/list", config)
      .then((res) => {
        this.linhao = res.data.linhao;
      })
      .catch((err) => {
        console.log(err);
      });
    // this.linhao = linhao.features;
    //Obter arruamento auxiliar
    this.$http
      .get("arruamento-aux/list", config)
      .then((res) => {
        this.arruamento_aux = res.data.arruamentoAux;
      })
      .catch((err) => {
        console.log(err);
      });
    // this.arruamento_aux = arruamentoAux.features;
    // Encerra o loading dos dados
    // setTimeout(() => {
    // this.$store.dispatch("mapa/setLoaderState", false);
    //   console.log('awaiting');
    // }, 5000);
  },
  methods: {
    async filterLotesGleba(payload) {
      // this.loteamentos = this.lotes;
      await this.loadLotes();
      const filteredLotes = this.loteamentos.filter(
        (item) =>
          item.properties.gleba === Number(payload.gleba) &&
          item.properties.quadra === payload.quadra
      );

      if (filteredLotes.length > 0) {
        this.loteamentos = filteredLotes;
        // console.log("***Home: ", filteredLotes.length);
      } else {
        this.$toasted.error(
          "Desculpe, nenhum lote dessa quadra foi encontrado!",
          {
            duration: 4500,
          }
        );
      }
    },
    setAllLotes() {
      // this.$store.dispatch("mapa/setLoaderState", true);
      this.loadLotes();
      // this.$store.dispatch("mapa/setLoaderState", false);
    },
    onCancelCreate() {
      this.$store.dispatch("mapa/setCreateMode", false);
      this.$store.dispatch("mapa/setNextStepCreate", false);
    },
    handleShowSideBar() {
      this.showLateralBarMobile = true;
    },
    handleBarOutside() {
      this.showLateralBarMobile = false;
    },
    async loadLotes() {
      // Se o estado estiver com os lotes, não carrega da API, apenas atribui a loteamentos
      // if (!Array.isArray(this.lotes) || this.lotes.length <= 0) {
      // this.$store.dispatch("mapa/setLoaderState", true);
      // const token = localStorage.getItem("token");
      // const config = { headers: { Authorization: `Bearer ${token}` } };
      // this.$http
      //   .get("loteamentos/list", config)
      //   .then((res) => {
      //     console.log("***Home/loadLotes - Loadead Lotes");
      //     // this.$store.dispatch("mapa/setLotes", res.data.loteamentos);
      //     this.loteamentos = res.data.loteamentos;
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
      // this.$store.dispatch("mapa/setLoaderState", false);
      // } else {
      //   // console.log('else', this.lotes);
      //   this.loteamentos = this.lotes;
      // }
      // this.loteamentos = loteamentos.loteamentos;
      try {
        this.$store.dispatch("mapa/setLoaderState", true);
        const token = localStorage.getItem("token");
        const config = { headers: { Authorization: `Bearer ${token}` } };
        const response = await this.$http.get("loteamentos/list", config);
        this.loteamentos = response.data.loteamentos;
        this.$store.dispatch("mapa/setLoaderState", false);
      } catch (error) {
        console.log(error);
      }
    },
    // async loadLoteAsync() {
    //   try {
    //     this.$store.dispatch("mapa/setLoaderState", true);
    //     const token = localStorage.getItem("token");
    //     const config = { headers: { Authorization: `Bearer ${token}` } };
    //     const response = await this.$http.get("loteamentos/list", config);
    //     this.loteamentos = response.data.loteamentos;
    //     this.$store.dispatch("mapa/setLoaderState", false);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    loadGlebas() {
      /*=========> CRIAR GLEBAS FROM API <================= */
      const token = localStorage.getItem("token");
      const config = { headers: { Authorization: `Bearer ${token}` } };
      this.$http
        .get("glebas/list", config)
        .then((res) => {
          // this.glebas = glebasList.glebas;
          this.glebas = res.data.glebas;
          this.$store.dispatch("mapa/setSelectedGleba", this.glebas[0]);
          // console.log('***Home: ', this.glebas);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    zoomplus: function () {
      this.$refs.map.zoomplus();
    },
    zoommine: function () {
      this.$refs.map.zoommine();
    },
    geoloc: function () {
      this.$refs.map.getLocation();
    },
    satelite: function () {
      this.$refs.map.satelite();
    },
    home: function () {
      this.$refs.map.home();
    },
    local: function () {
      this.$refs.map.local();
    },
    filtrar: function (value) {
      this.filter = value;
    },
    filtercolor: function (value) {
      this.choiceColor = value;
    },
    getSit: function (value) {
      switch (value) {
        case "#00a":
          return "QUITADO";
        case "#fff":
          return "ESCRITURA";
        case "#a00":
          return "ATRASADO";
        case "#0a0":
          return "EMDIA";
        case "#aaa":
          return "ESTOQUE";
        case "#aa2":
          return "RESERVADO";
        default:
          return "";
      }
    },
    habilitarEdit: function () {
      this.edit = true;
    },
    addLote: function () {
      this.add = true;
    },
    removeLote: function () {
      this.remove = true;
    },
    deleteLotes: function () {
      const token = localStorage.getItem("token");
      let config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      const data = { ids: this.selectedLots };
      this.$http.post("loteamentos/delete-many", data, config);
      // for (let id in this.selectedLots) {
      //   var lotesDeleteds = this.$loteService.deleteLoteamento(
      //     id,
      //     this.loteamentos
      //   );
      // }
      // this.$store.dispatch("mapa/setLotes", lotesDeleteds);
      this.loadLotes();
    },
    save: function () {
      this.edit = false;
      this.add = false;
      this.remove = false;
    },
    cancel: function () {
      this.edit = false;
      this.add = false;
      this.remove = false;
    },
  },
  computed: {
    createMode() {
      return this.$store.getters["mapa/getCreateMode"];
    },
    nextStepCreate() {
      return this.$store.getters["mapa/getNextStepCreate"];
    },
    lotes() {
      return this.$store.getters["mapa/getLotes"];
    },
    listLotes: function () {
      if (
        this.choiceColor.includes("#") &&
        (this.filter == " " || this.filter == "")
      ) {
        var sit = this.getSit(this.choiceColor);
        return this.loteamentos.filter((lote) => lote.properties.sit2 == sit);
      } else if (
        (this.filter != " " || this.filter != "") &&
        (this.choiceColor == "" || this.choiceColor == " ")
      ) {
        return this.loteamentos.filter((lote) => {
          for (let [value] of Object.entries(lote.properties)) {
            if (
              typeof value === "string" &&
              value.toString().toLowerCase().includes(this.filter.toLowerCase())
            ) {
              return lote;
            }
          }
        });
      } else {
        return this.loteamentos;
      }
    },
    user() {
      let user = this.$store.getters["auth/getUser"];
      return user;
    },
  },
};
</script>

<style scoped>
.mapa {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
}
#mobile-bar {
  display: none;
}

#cancel-create-mode {
  z-index: 10000;
  position: absolute;
  background: rgba(0, 0, 0, 0.65);
  width: 250px;
  left: 60%;
  top: 15px;
  transform: translate(-60%);
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

@media screen and (min-width: 480px) and (max-width: 768px) {
  .lateral-bar-desktop {
    display: none;
  }
  .lateral-bar-mobile {
    display: block;
  }
  #mobile-bar {
    display: block;
    z-index: 1;
  }
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  .lateral-bar-desktop {
    display: none;
  }
  .lateral-bar-mobile {
    display: block;
  }
  #mobile-bar {
    display: block;
    z-index: 1;
  }
}
</style>
