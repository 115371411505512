<template>
  <div class="card">
    <div class="card-body">
      <div class="table">
        <div class="line">
          <span>FID</span>
          <input type="text" v-model="value.fid" />
        </div>
        <div v-for="value in value.loteData" :key="value.label" class="line">
          <span>{{ value.label }}</span>
          <custom-select
            v-if="value.label === 'Situação'"
            v-model="value.value"
          />
          <input v-else type="text" v-model="value.value" />
        </div>
        <div class="observation">
          <span>Observações</span>
          <textarea type="text" v-model="value.observation" />
        </div>
      </div>
      <button @click="save()">Salvar alterações</button>
      <button @click="$emit('onCancel')" style="margin-top: 8px">
        Cancelar
      </button>
    </div>
  </div>
</template>

<script>
import CustomSelect from "./LateralBar/CustomSelect.vue";
export default {
  components: { CustomSelect },
  props: {
    lote: Object,
    edit: Boolean,
    create: Boolean,
    editmode: Boolean,
    value: {
      required: false,
    },
  },
  data() {
    return {
      selected: false,
    };
  },
  watch: {
    selected(value) {
      if (value) {
        this.$emit("onSelect");
      } else {
        this.$emit("onUnselect");
      }
    },
  },

  methods: {
    save() {
      /* eslint-disable */
      let data = this.value.loteData.map((obj) => {
        let key = Object.entries(obj).find(([key, val]) => key === "key")[1];
        return {
          [key]: obj.value,
        };
      });

      let object = {};
      data.forEach((obj) => {
        Object.assign(object, obj);
      });

      let lote = this.value;
      lote.properties = object;
      lote.properties.fid = lote.fid;

      this.$emit("onSave", lote);
      this.$emit("onEdit", lote);
      this.more = false;
    },
    handlerCreate(lote) {
      this.$emit("onCreate", lote);
    },
    handlerSave() {
      this.$emit("onSave");
    },
    handlerEdit() {
      this.$emit("onEdit");
      this.more = false;
    },
    handlerDelete() {
      this.$emit("onDelete");
      this.more = false;
    },
  },
};
</script>

<style scoped>
.card {
  display: flex;
  flex-direction: column;
  color: #626262;
  background: #e6e6e6;
  margin-bottom: 16px;
  z-index: 3;
}
.card-body {
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
}
.color {
  height: 3px;
  width: 50%;
}
.card-header {
  display: flex;
  align-items: flex-start;
}
.card-header span {
  font-size: 18px;
  cursor: pointer;
}
.card-header h3 {
  text-align: left;
  width: 100%;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 8px;
}
.selection-card {
  display: flex;
  position: absolute;
  z-index: 3;
  flex-direction: column;
  justify-content: center;
  background: hsl(0, 0%, 95%);
  padding: 4px 8px;
  width: 100px;
  margin-left: 250px;
  margin-top: -10px;
}
.selection-card span {
  background: #d8d8d8;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  color: #626262;
  margin: 4px 0px;
  padding: 8px;
}
.table {
  display: flex;
  flex-direction: column;
}
.table .line {
  display: flex;
  align-items: center;
}
.table .line span {
  background: #d8d8d8;
  display: flex;
  align-items: center;
  width: 50%;
  height: 24px;
  margin-bottom: 8px;
  font-size: 14px;
  padding: 4px 4px;
}
.table .line input {
  background: #d8d8d8;
  color: #626262;
  display: flex;
  align-items: center;
  width: 50%;
  outline: 0;
  border: none;
  height: 24px;
  margin-left: 8px;
  margin-bottom: 8px;
  font-size: 14px;
  padding: 4px 4px;
}
.table .line .value {
  margin-left: 8px;
}
.table .observation {
  display: flex;
  text-align: left;
  flex-direction: column;
  flex: 1;
  background: #d8d8d8;
  margin-bottom: 8px;
  padding: 4px;
}
.table .observation span {
  font-weight: medium;
  margin-bottom: 8px;
  font-size: 16px;
}
.table .observation textarea {
  color: #626262;
  background: #d8d8d8;
  display: flex;
  align-items: center;
  width: 88%;
  outline: 0;
  border: none;
  height: 24px;
  margin-left: 8px;
  margin-bottom: 8px;
  font-size: 14px;
  padding: 4px 4px;
  height: 100px;
}
button {
  cursor: pointer;
  border: none;
  background-color: #0b245b;
  color: white;
  padding: 12px 16px;
}
button:hover {
  background-color: #091d49;
}
button:active {
  background-color: #10285a;
}
</style>
