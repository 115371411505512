import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "./assets/css/main.css";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "material-icons/iconfont/material-icons.css";
import "./utils/directives";
import VueMeta from "vue-meta";
import Toasted from "vue-toasted";
import useAxios from "./utils/axios";
import VueExpandableImage from "vue-expandable-image";

import loader from "vue-ui-preloader";
Vue.use(loader);

import Paginate from "vuejs-paginate";
import JwPagination from "jw-vue-pagination";
Vue.component("jw-pagination", JwPagination);
Vue.component("paginate", Paginate);

Vue.use(VueExpandableImage);
Vue.use(VueMeta);
Vue.use(Toasted);
Vue.config.productionTip = false;
useAxios(Vue);

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
