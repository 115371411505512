const state = {
  user: false,
  token: false,
  permissionLevel: false,
};

const getters = {
  getUser(state) {
    return state.user;
  },
  getToken(state) {
    return state.token;
  },
  getPermissionLevel(state) {
    return state.permissionLevel;
  },
};

const mutations = {
  SET_USER(state, value) {
    state.user = value;
  },
  SET_PERMISSION_LEVEL(state, value) {
    state.permissionLevel = value;
  },
  SET_TOKEN(state, value) {
    state.token = value;
  },
};

const actions = {
  clearUser({ commit }) {
    commit("SET_USER", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
