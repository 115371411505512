<template>
  <div class="bodyLogin">
    <div v-if="!this.esqueci">
      <div class="inputs" v-for="(option, index) in options" :key="index">
        <input
          :type="option.type"
          v-model="option.result"
          :placeholder="option.label"
          v-on:keyup.enter="login"
        />
        <span
          v-if="option.label === `Senha` && !option.see"
          class="material-icons icon"
          @click="visibility"
        >
          visibility
        </span>
        <span
          v-else
          v-show="option.label === `Senha` && option.see"
          class="material-icons icon"
          @click="visibility"
        >
          visibility_off
        </span>
      </div>
    </div>
    <div v-else class="inputs">
      <input type="text" v-model="email" placeholder="Insira seu E-mail" />
    </div>

    <div
      v-if="!this.esqueci"
      class="rememberpassword"
      @click="rememberPassword"
    >
      Esqueci a senha
    </div>
    <div v-else class="rememberpassword" @click="cancel">Cancelar</div>
    <Button v-if="!this.esqueci" class="buttonLogin" @click.native="login" v-on:keyup.enter="login">
      Entrar
    </Button>
    <Button v-else class="buttonLogin" @click.native="sendEmail">
      Enviar e-mail
    </Button>
  </div>
</template>

<script>
import Button from "../global/GenericButton";
export default {
  components: {
    Button,
  },
  data() {
    return {
      email: "",
      esqueci: false,
      options: [
        { label: "Login", see: false, result: "", type: "text" },
        { label: "Senha", see: true, result: "", type: "password" },
      ],
    };
  },
  methods: {
    login: function () {
      this.$emit("login", this.options[0].result, this.options[1].result);
    },
    visibility: function () {
      this.options[1].see = !this.options[1].see;
      if (this.options[1].type == "password") {
        this.options[1].type = "text";
      } else {
        this.options[1].type = "password";
      }
    },
    sendEmail: function () {
      if (this.email != "") {
        this.$emit("esqueci", this.email);
        this.esqueci = false;
      } else {
        console.log("insira um campo válido");
      }
    },
    rememberPassword: function () {
      this.esqueci = true;
    },
    cancel: function () {
      this.esqueci = false;
    },
  },
};
</script>

<style  scoped>
.rememberpassword {
  font-size: 1vh;
  font-size: 18px;
  text-align: left;
  cursor: pointer;
}

.bodyLogin {
  width: 100% !important;
}

input {
  width: 100%;
  height: 2px;
  margin-bottom: 20px;
  border: none;
  font-size: 20px;
  padding: 25px;
}

input:focus {
  border: none;
}

.inputs {
  display: flex;
}

.icon {
  background: white;
  font-size: 30px;
  cursor: pointer;
  width: 25%;
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: center;
}

.buttonLogin {
  width: 100%;
  margin-top: 25px;
  font-size: 20px;
}
</style>