<template>
  <div class="custom-select-wrapper">
    <div class="custom-select">
      <div>
        <span @click="custom = !custom" class="selected-option default">
          <div
            class="quad-color"
            :style="`background:${getColor(value)};`"
          ></div>
          {{ value }}
        </span>
      </div>
      <div v-show="custom" class="custom-options">
        <div>
          <span @click="custom = !custom" class="selected-option default">
            <div
              class="quad-color"
              :style="`background:${getColor(value)};`"
            ></div>
            {{ value }}
          </span>
        </div>
        <span
          class="custom-option"
          v-for="opt in options"
          :key="opt"
          @click="select(opt)"
          :class="value == opt ? 'selected' : ''"
        >
          <div
            @click="select(opt)"
            class="quad-color"
            :style="`background:${getColor(opt)};`"
          ></div>
          {{ opt }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { colors } from '../../utils/colors'
export default {
  data() {
    return {
      custom: false,
      selected: '',
      options: [
        'ATRASADO',
        'EM DIA',
        'ESCRITURA',
        'ESTOQUE',
        'INDEFINIDO',
        'QUITADO',
        'RESERVADO'
      ]
    }
  },
  props: {
    value: {
      required: false
    }
  },
  methods: {
    select(value) {
      this.custom = false
      this.$emit('input', value)
    },
    getColor(value) {
      return colors[value] || '#D91'
    }
  }
}
</script>

<style scoped>
.quad-color {
  margin-right: 8px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  border: 1px solid #ccc;
}

.custom-select-wrapper {
  position: relative;
  user-select: none;
  width: 58%;
}
.custom-select {
  display: flex;
  flex-direction: column;
}
.custom-options {
  display: flex;
  position: absolute;
  background: #d8d8d8;
  flex-direction: column;
}

.custom-option {
  position: relative;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: 8px 8px;
  font-weight: 300;
  color: #3b3b3b;
  transition: all 0.5s;
}
.custom-option:hover {
  cursor: pointer;
  background-color: #ccc;
}
.custom-option.selected {
  background-color: #aaa;
  color: white;
}

.default {
  background: #d8d8d8;
  display: flex;
  align-items: center;
  height: 24px;
  margin-bottom: 8px;
  margin-left: 8px;
  font-size: 14px;
  padding: 4px 4px;
}
</style>
