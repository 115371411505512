<template>
  <div id="container">
    <header>
      <img
        src="../../assets/cropped-cropped-cropped-bg-bemviver2.jpg"
        width="1400"
        height="400"
        alt="Croped"
      />
      <div id="wrapper">
        <h1>ITASOL Empreendimentos I. & M.</h1>
        <button @click="accessLotes()">Acessar lotes</button>
      </div>
    </header>
    <section
      v-for="(section, index) in sections"
      :key="index"
      v-bind:style="{ backgroundImage: 'url(' + section.background + ')' }"
    >
      <h1 class="title-entry">{{ section.title }}</h1>
      <p class="info-text">{{ section.text }}</p>
      <button class="more-info" @click="route(section.route)">
        Continuar Lendo
      </button>
    </section>
    <section
      v-bind:style="{
        backgroundImage:
          'url(https://itasolblog.files.wordpress.com/2015/12/contato1.jpg?w=1400&h=882&crop=1)',
      }"
    >
      <h1 class="title-entry">Contato</h1>
      <p class="info-text">
        A Itasol agradece a sua visita e encontra-se ao dispor para contatos
        futuros. Nosso site:
        <a class="link-contact" href="http://www.itasol.com.br/" rel="nofollow"
          >http://www.itasol.com.br</a
        >
        E-mail:
        <a class="link-contact" href="mailto:contato@itasol.com.br"
          >contato@itasol.com.br</a
        >
        Telefone: +55 21 2220-2959 Endereço: Rua Dr. Pereira Santos,...
      </p>
      <button class="more-info" @click="route('contato')">
        Continuar Lendo
      </button>
    </section>
    <footer
      v-bind:style="{
        backgroundImage:
          'url(https://itasolblog.files.wordpress.com/2015/12/cropped-cropped-cropped-bg-bemviver2.jpg)',
      }"
    >
      <p>
        2016 Itasol Empreendimentos Imobiliários & Mineração LTDA. Todos os
        direitos reservados.
      </p>
    </footer>
  </div>
</template>

<script>
export default {
  metaInfo: {
    // Children can override the title.
    title: "ITASOL Empreendimentos I. & M. LTDA",
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site
    titleTemplate: "%s",
    // Define meta tags here.
    meta: [
      { "http-equiv": "Content-Type", content: "text/html; charset=utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      {
        name: "description",
        content:
          "Fundada no final da década de 1970, a Itasol Empreendimentos Imobiliários & Mineração é proprietária de cerca de 750 hectares em Manilha, distrito da cidade de Itaboraí, Rio de Janeiro.",
      },
      {
        name: "keywords",
        content:
          "itasol, imobiliaria, mineração, lotes, glebas, quadras, loteamentos",
      },
      { name: "robots", content: "index,follow" },
      // OpenGraph data (Most widely used)
      { property: "og:title", content: "Itasol Imobiliária" },
      { property: "og:site_name", content: "Itasol Imobiliária" },
      // The list of types is available here: http://ogp.me/#types
      { property: "og:type", content: "website" },
      // Should the the same as your canonical link, see below.
      { property: "og:url", content: "http://www.itasol.com.br" },
      // Often the same as your meta description, but not always.
      {
        property: "og:description",
        content:
          "Fundada no final da década de 1970, a Itasol Empreendimentos Imobiliários & Mineração é proprietária de cerca de 750 hectares em Manilha, distrito da cidade de Itaboraí, Rio de Janeiro.",
      },
    ],
  },
  data() {
    return {
      sections: [
        {
          title: "Sobre Nós",
          text: "Fundada no final da década de 1970, a Itasol Empreendimentos Imobiliários & Mineração é proprietária de cerca de 750 hectares em Manilha, distrito da cidade de Itaboraí, Rio de…",
          background:
            "https://itasolblog.files.wordpress.com/2015/12/sobrenos2.jpg?w=1400&h=882&crop=1",
          route: "sobre",
        },
        {
          title: "Loteamentos",
          text: "Detentora de mais de 7 milhões de m² loteados no município de Itaboraí (a apenas 45 minutos da capital carioca), a Itasol conta com um excepcional landbank…",
          background:
            "https://itasolblog.files.wordpress.com/2015/12/loteamentos1.jpg?w=1400&h=882&crop=1",
          route: "loteamentos",
        },
        {
          title: "Mineração",
          text: "A Itasol guarda para si grande interesse no aproveitamento adequado de sua área, especialmente no que diz respeito a implementação de diferentes empreendimentos (além do setor imobiliário). E…",
          background:
            "https://itasolblog.files.wordpress.com/2015/12/mineracao1.jpg?w=1400&h=882&crop=1",
          route: "mineracao",
        },
      ],
    };
  },
  methods: {
    route(rota) {
      this.$router.push(`/${rota}`);
    },
    accessLotes() {
      const isAuth = this.$store.getters["auth/getUser"];
      if (isAuth) {
        this.$router.push("/home");
      } else {
        this.$router.push("/login");
      }
    },
  },
};
</script>

<style scoped>
#container {
  width: 100%;
  height: 100%;
  font-family: "Cinzel Regular", serif;
  background: #e6ddcd;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 90%;
}

#wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#wrapper > h1 {
  font-weight: 400;
  font-size: 1.5rem;
  z-index: 10;
  color: #fcfbf9;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  text-shadow: 0.1em 0.1em 0.2em black;
}

#wrapper > button {
  font-size: 0.9rem;
  z-index: 10;
  padding: 12px 24px;
  background: transparent;
  margin-top: 1.5rem;
  color: #83b6cc;
  border-radius: 0.2rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  border: 2px solid #83b6cc;
  text-transform: uppercase;
  font-family: "Cinzel", serif;
  transition: all 0.2s ease-in-out;
}

#wrapper > button:hover {
  background: #83b6cc;
  border: 2px solid #83b6cc;
  color: white;
  cursor: pointer;
}

img {
  height: auto;
  max-width: 100%;
  min-width: 100%;
}

/* Sections */
section {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

/* section:before {
    background: rgba(0, 0, 0, 0.5);
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
} */

.title-entry {
  border: 2px solid #fcfbf9;
  border-bottom: 0;
  color: #fcfbf9;
  display: inline-block;
  /* max-width: 100%; */
  padding: 0.75rem 1.5rem 0.85rem;
  position: relative;
  text-transform: uppercase;
  word-break: break-word;
  word-wrap: break-word;
  margin: 3rem 0;
  letter-spacing: 0.1rem;
  font-weight: 400;
  font-size: 1.6rem;
}

.title-entry::after {
  border-bottom: 2px solid #fcfbf9;
  bottom: 0;
  right: 0;
  content: "";
  display: block;
  height: 0;
  position: absolute;
  width: 50px;
}

.title-entry::before {
  border-bottom: 2px solid #fcfbf9;
  bottom: 0;
  left: 0;
  content: "";
  display: block;
  height: 0;
  position: absolute;
  width: 50px;
}

.info-text {
  display: block;
  margin: 0 auto;
  max-width: 60%;
  color: #fcfbf9;
  font-size: 1.2rem;
  padding: 1rem;
  line-height: 2rem;
  font-family: "Quattrocento Regular", sans-serif;
}

.more-info {
  background: transparent;
  position: relative;
  color: #83b6cc;
  text-transform: uppercase;
  font-size: 1.2rem;
  margin: 0.5rem 0 10rem 0;
  padding: 12px 24px;
  transition: all 0.2s ease-in;
}

.more-info:hover {
  filter: brightness(0.8);
  cursor: pointer;
}

.more-info:hover::after {
  border-bottom: 2px solid #83b6cc;
  bottom: 0;
  left: 30px;
  content: "";
  display: block;
  height: 0;
  position: absolute;
  width: 75%;
}

.link-contact {
  color: #83b6cc;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.link-contact:hover {
  text-decoration: underline;
  filter: brightness(0.8);
}

/* Footer */
footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 90%;
}

footer > p {
  padding: 1rem 1rem;
  color: #fcfbf9;
}

@media screen and (min-width: 480px) and (max-width: 768px) {
  header {
    width: 100%;
  }

  section {
    width: 100%;
  }

  #wrapper > h1 {
    font-size: 1rem;
  }

  #wrapper > button {
    padding: 0.5rem 1rem;
  }

  .info-text {
    max-width: 80%;
  }
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  header {
    height: 150px;
    width: 100%;
  }

  header > img {
    height: 100%;
  }

  section {
    width: 100%;
  }

  #wrapper > h1 {
    font-size: 1rem;
  }

  #wrapper > button {
    padding: 0.5rem 0.5rem;
    font-size: 0.8rem;
  }

  .info-text {
    max-width: 80%;
  }
}
</style>