<template>
  <div class="card">
    <div
      v-if="cardType === `lote`"
      class="color"
      :style="`background: ${color}`"
    ></div>
    <div class="card-body">
      <div class="card-header" @click="collapsibled = !collapsibled">
        <input type="checkbox" v-model="selected" v-if="editmode" />
        <h3 v-if="cardType === `lote`">{{ lote.qtd }}</h3>
        <h3 v-else-if="cardType === `gleba`">{{ gleba.quadra }}</h3>
        <span
          v-if="!edit && permissionLevel < 2 && cardType === `lote`"
          class="material-icons white"
          @click="seeMore"
          >more_vert</span
        >
        <div
          v-if="!edit && !create && cardType === `lote`"
          v-show="more"
          class="selection-card"
        >
          <span @click="handlerEdit()">Editar</span>
          <span @click="handlerDelete()">Excluir</span>
        </div>
      </div>

      <div
        v-if="cardType === `lote`"
        :class="`${collapsibled ? `table` : `table-none`}`"
      >
        <div v-for="lote in lote.loteData" :key="lote.label" class="line">
          <span>{{ lote.label }}</span>

          <span v-if="!edit && !create" class="value">{{ lote.value }}</span>

          <custom-select
            v-else-if="lote.label === 'Situação'"
            v-model="lote.value"
          />

          <input v-else type="text" v-model="lote.value" />
        </div>
        <div v-if="lote.observation.length > 1 || edit" class="observation">
          <span>Observações</span>
          <p v-if="!edit && !create">{{ lote.observation }}</p>
          <textarea v-else type="text" v-model="lote.observation" />
        </div>
      </div>

      <div
        v-else-if="cardType === `gleba`"
        :class="`${collapsibled ? `table` : `table-none`}`"
      >
        <div v-for="(gleb, index) in gleba.glebaData" :key="index" class="line">
          <span>{{ gleb.label }}</span>
          <span v-if="!edit && !create" class="value">{{ gleb.value }}</span>
        </div>
      </div>

      <div id="details-filter-gleba" v-if="cardType === `gleba`">
        <h2
          v-if="gleba.quadra !== currentQuadra"
          class="see-filter-lotes"
          @click="seeFilterLotes(gleba.quadra)"
        >
          Ver Lotes
        </h2>
        <h2 v-else class="see-filter-lotes" @click="setAllLotes">
          Todos os lotes
        </h2>
      </div>

      <button v-if="(edit || (create && cardType === `lote`)) && lote.id === currentLote.id" @click="save()">
        Salvar alterações
      </button>
      <button
        @click="$emit('onCancel')"
        v-if="edit && cardType === `lote` && lote.id === currentLote.id"
        style="margin-top: 8px"
      >
        Cancelar
      </button>
    </div>
  </div>
</template>

<script>
import { colors } from "../../utils/colors";
import CustomSelect from "./CustomSelect.vue";
export default {
  components: { CustomSelect },
  props: {
    lote: Object,
    gleba: Object,
    edit: Boolean,
    create: Boolean,
    editmode: Boolean,
    cardType: {
      String,
      default: "lote",
    },
  },
  data() {
    return {
      more: false,
      selected: false,
      collapsibled: false,
    };
  },
  watch: {
    selected(value) {
      if (value) {
        this.$emit("onSelect");
      } else {
        this.$emit("onUnselect");
      }
    },
  },
  computed: {
    color() {
      return colors[this.lote.color] || "#d91";
    },
    currentQuadra() {
      return this.$store.getters["mapa/getCurrentQuadra"];
    },
    currentLote() {
      return this.$store.getters["mapa/getCurrentLote"];
    },
    permissionLevel() {
      return this.$store.getters["auth/getPermissionLevel"];
    },
    selectedGleba() {
      return this.$store.getters["mapa/getSelectedGleba"];
    },
  },
  methods: {
    seeFilterLotes(quadra) {
      // console.log("***Card: ", gleba);
      // console.log('***Card Selected: ', this.selectedGleba);
      this.$store.dispatch("mapa/setCurrentQuadra", quadra);
      const payloadFilter = {
        gleba: this.selectedGleba.gleba,
        quadra,
      };
      this.$emit("filterLotesGleba", payloadFilter);
    },
    setAllLotes() {
      this.$emit("setAllLotes");
      this.$store.dispatch("mapa/setCurrentQuadra", "");
    },
    seeMore() {
      this.collapsibled = false;
      this.more = !this.more;
    },
    save() {
      if (this.edit) {
        return this.handlerSave();
      }
      if (this.create) {
        return this.handlerCreate();
      }
      this.more = false;
    },
    handlerCreate() {
      this.$emit("onCreate");
    },
    handlerSave() {
      let observation = {
        key: "observacao",
        label: "Observacao",
        value: this.lote.observation,
      };

      var objCloned = Object.assign({}, this.lote);
      var loteData = this.lote.loteData;
      const newArray = [...loteData, observation];
      objCloned.loteData = newArray;

      // console.log('***Card: ', objCloned);

      this.$emit("onSave", objCloned);
    },
    handlerEdit() {
      /* eslint-disable */
      // let data = this.lote.loteData.map((obj) => {
      //   let key = Object.entries(obj).find(([key, val]) => key === "key")[1];
      //   return {
      //     [key]: obj.value,
      //   };
      // });

      // let object = {};
      // data.forEach((obj) => {
      //   Object.assign(object, obj);
      // });

      // let lote = this.lote;
      // lote.properties = object;
      this.$store.dispatch("mapa/setCurrentLote", this.lote);
      this.collapsibled = !this.collapsibled;
      this.$emit("onEdit");
      this.more = false;
    },
    handlerDelete() {
      this.$emit("onDelete");
      this.more = false;
    },
  },
};
</script>

<style scoped>
.card {
  display: flex;
  flex-direction: column;
  color: #626262;
  background: #e6e6e6;
  margin-bottom: 16px;
  z-index: 3;
}
.card-body {
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
}
.color {
  height: 3px;
  width: 50%;
}
.card-header {
  display: flex;
  align-items: flex-start;
}

.card-header span {
  font-size: 18px;
  cursor: pointer;
  /* background: red; */
}
.card-header h3 {
  text-align: left;
  width: 100%;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 8px;
}
.selection-card {
  display: flex;
  position: absolute;
  z-index: 40;
  flex-direction: column;
  justify-content: center;
  background: hsl(0, 0%, 95%);
  padding: 4px 8px;
  width: 100px;
  margin-left: 200px;
  margin-top: 20px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
}
.selection-card span {
  background: #d8d8d8;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  color: #626262;
  margin: 4px 0px;
  padding: 8px;
}

#details-filter-gleba {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.6rem 0;
}

.see-filter-lotes {
  margin-right: 0.5rem;
  /* padding: 0.6rem 0.2rem; */
  font-weight: 600;
  transition: all 0.2s ease-in;
}

.see-filter-lotes:hover {
  color: #25304c;
  border-bottom: 1px solid #25304c;
}

.table {
  display: flex;
  flex-direction: column;
  transition: all 0.2s linear;
}

.table-none {
  display: none;
}

.table .line {
  display: flex;
  align-items: center;
}
.table .line span {
  background: #d8d8d8;
  display: flex;
  align-items: center;
  width: 50%;
  height: 24px;
  margin-bottom: 8px;
  font-size: 14px;
  padding: 4px 4px;
}
.table .line input {
  background: #d8d8d8;
  color: #626262;
  display: flex;
  align-items: center;
  width: 50%;
  outline: 0;
  border: none;
  height: 24px;
  margin-left: 8px;
  margin-bottom: 8px;
  font-size: 14px;
  padding: 4px 4px;
}
.table .line .value {
  margin-left: 8px;
}
.table .observation {
  display: flex;
  text-align: left;
  flex-direction: column;
  flex: 1;
  background: #d8d8d8;
  margin-bottom: 8px;
  padding: 4px;
  word-wrap: break-word;
  width: 100%;
  max-height: 150px;
  overflow-y: scroll;
}
.table .observation span {
  font-weight: medium;
  margin-bottom: 8px;
  font-size: 16px;
}

.table .observation textarea {
  color: #626262;
  background: #d8d8d8;
  display: flex;
  align-items: center;
  width: 88%;
  outline: 0;
  border: none;
  height: 24px;
  margin-left: 8px;
  margin-bottom: 8px;
  font-size: 14px;
  padding: 4px 4px;
  height: 100px;
}
button {
  cursor: pointer;
  border: none;
  background-color: #0b245b;
  color: white;
  padding: 12px 16px;
}
button:hover {
  background-color: #091d49;
}
button:active {
  background-color: #10285a;
}
@media screen and (min-width: 480px) and (max-width: 768px) {
  .selection-card {
    margin-left: 160px;
  }
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  .selection-card {
    margin-left: 140px;
  }
}
</style>
