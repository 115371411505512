import Vue from "vue";
import Vuex from "vuex";
import Auth from "./modules/AuthStore";
import File from "./modules/FileStore";
import Mapa from "./modules/MapStore";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],

  namespaced: true,

  modules: {
    auth: Auth,
    file: File,
    mapa: Mapa,
  },
});
