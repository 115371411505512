<template>
  <ul class="pagination">
    <li
      class="page-item previous"
      :class="{ disabled: current_page === 0 }"
      @click="prevItem"
    >
      <span
        class="material-icons-outlined"
        :class="{ disabled: current_page === 0 }"
      >
        chevron_left
      </span>
    </li>
    <li
      v-for="(gleba, index) in chunkedGlebas"
      :key="index"
      class="page-item page-number"
    >
      <a
        class="page-link"
        :class="{ active: selectedGleba.gleba === gleba.gleba }"
        @click="setGleba(gleba)"
        >{{ gleba.gleba }}</a
      >
    </li>
    <li
      class="page-item next"
      :class="{ disabled: current_page === items.length - 1 }"
      @click="nextItem"
    >
      <!-- <a class="page-link">></a> -->
      <span
        class="material-icons-outlined"
        :class="{ disabled: current_page === items.length }"
      >
        navigate_next
      </span>
    </li>
  </ul>
</template>

<script>
// import paginate from "jw-paginate";

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      current_page: 0,
    };
  },
  created() {
    // console.log('***GlebasList: ', this.items);
    if (this.items && this.items.length && !this.selectedGleba) {
      this.setGleba(this.items[0]);
    } else {
      this.current_page = this.items.findIndex(
        (item) => item.gleba === this.selectedGleba.gleba
      );
    }
    // console.log("*** LateralBar/GlebasList: ", this.items);
  },
  computed: {
    selectedGleba() {
      return this.$store.getters["mapa/getSelectedGleba"];
    },
    chunkedGlebas() {
      if (this.current_page + 4 > this.items.length) {
        return this.items.slice(this.items.length - 4, this.items.length);
      } else {
        return this.items.slice(this.current_page, this.current_page + 4);
      }
    },
  },
  methods: {
    setGleba(gleba) {
      this.$store.dispatch("mapa/setSelectedGleba", gleba);
      this.current_page = this.items.findIndex(
        (item) => item.gleba === gleba.gleba
      );
    },
    nextItem() {
      this.current_page = this.current_page + 1;
      this.setGleba(this.items[this.current_page]);
    },
    prevItem() {
      this.current_page = this.current_page - 1;
      this.setGleba(this.items[this.current_page]);
    },
  },
};
</script>

<style scoped>
.pagination {
  padding: 1.2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  overflow: hidden;
  padding: 1.5rem 0;
}

.previous {
  margin-right: 0.5rem;
}

.next {
  margin-left: 0.5rem;
}

.page-item {
  margin: 0 0.5rem;
}

.page-link {
  padding: 0.6rem;
  font-weight: 600;
  font-size: 1.1rem;
}

.active {
  height: 100%;
  background-color: #c4c4c4;
  border-radius: 0.5rem;
}

.disabled {
  pointer-events: none;
  color: red;
}
</style>
