<template>
  <div
    :class="
      !createMode ? `lateral-bar-right-configs` : `lateral-bar-center-configs`
    "
  >
    <div class="config-buttons">
      <div class="action-buttons">
        <span v-if="permissionLevel < 2" @click.stop>
          <Button v-if="!config" @click.native="openSettings">
            <img
              src="../../assets/icons/settings.svg"
              alt
              height="24"
              class="logo"
            />
          </Button>

          <div v-show="config" class="dialog">
            <Dialog
              title="Configurações"
              :options="options"
              @goToUsers="goToUsers"
              @goToFiles="goToFiles"
              @close="closeSettings"
              @onCreate="onCreate"
              @onEdit="handlerEdit"
              @onDelete="handlerDelete"
              @onCancel="handlerCancel"
              v-click-outside="closeSettings"
            />
          </div>

          <div v-show="create && nextStepCreate" class="dialog">
            <Dialog
              title="Criar loteamento"
              :fullscreen="true"
              @close="onCancelCreate"
              @onCreate="handlerCreate"
              @onCancel="onCancelCreate"
            >
              <CreateLot
                v-model="lote"
                @onSave="handlerSave"
                @onCancel="onCancelCreate"
              />
            </Dialog>
          </div>
        </span>

        <Button v-if="!help" @click.native="help = true">
          <img
            src="../../assets/icons/question.svg"
            alt
            height="24"
            class="logo"
          />
        </Button>
        <div v-show="help" class="dialog">
          <Dialog
            title="Ajuda"
            @close="help = false"
            text="O mapa a seguir mostra as informações de acordo com o seu nível de acesso."
          />
        </div>
        <Button @click.native="handlerLogout()">
          <img
            src="../../assets/icons/logout.svg"
            alt
            height="24"
            class="logo"
          />
        </Button>

        <!-- <div v-show="admin" class="dialog">
          <Dialog
            title="Criar ADM"
            :fullscreen="true"
            @close="admin = false"
            :options="admOptions"
            @onCancel="admin = false"
          >
            <input
              v-model="adminEmail"
              class="input-admin"
              placeholder="Digite o e-mail"
            />
          </Dialog>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import Dialog from "../global/Dialog";
import CreateLot from "../CreateLot.vue";
import Button from "./Button";
export default {
  components: {
    Button,
    Dialog,
    CreateLot,
  },
  props: {
    editmode: {
      required: true,
    },
    loteamento: Array
  },
  data() {
    return {
      config: false,
      help: false,
      create: false,
      admin: false,
      adminEmail: "",
      options: [
        { label: "Adicionar lotes", event: "onCreate" },
        { label: "Excluir vários lotes", event: "onDelete" },
        { label: "Controle de Arquivos", event: "goToFiles" },
        { label: "Controle de Usuários", event: "goToUsers" },
      ],
      admOptions: [
        { label: "Criar administrador", event: "onCreate" },
        { label: "Cancelar", event: "onCancel" },
      ],
      lote: {
        fid: "",
        observation: "",
        type: "Feature",
        loteData: [
          { label: "Lote", value: "", key: "lote" },
          {
            label: "Quadra",
            value: "",
            key: "quadra",
          },
          {
            label: "Gleba",
            value: "",
            key: "gleba",
          },
          {
            label: "Área(m2)",
            value: "",
            key: "area",
          },
          {
            label: "Situação",
            value: "",
            key: "sit2",
          },
          {
            label: "Plano",
            value: "",
            key: "plano",
          },
          { label: "Gab", value: "", key: "gab" },
          {
            label: "Custo",
            value: "",
            key: "custo",
          },
          {
            label: "Cód. Comprador",
            value: "",
            key: "cod_comprador",
          },
          {
            label: "Nome Comprador",
            value: "",
            key: "nome_comprador",
          },
        ],
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [-42.925169129237624, -22.764887450023956],
              [-42.924630770477521, -22.763680239413855],
              [-42.924047548487444, -22.762397801497915],
              [-42.923427620218249, -22.761141677611075],
              [-42.922893339933594, -22.759975803784545],
              [-42.922420236780816, -22.758949074398441],
              [-42.921849250217065, -22.757711723600217],
              [-42.921221164996986, -22.756406663651472],
              [-42.92062570758052, -22.755191856009606],
              [-42.920111819673146, -22.754010887140833],
              [-42.919507119252266, -22.752779446925096],
            ],
          ],
        },
      },
    };
  },
  methods: {
    onCreate() {
      this.create = true;
      this.$store.dispatch("mapa/setCreateMode", true);
      // let createMode = this.$store.getters["mapa/getCreateMode"];
    },
    onCancelCreate() {
      this.create = false;
      this.$store.dispatch("mapa/setCreateMode", false);
      this.$store.dispatch("mapa/setNextStepCreate", false);
    },
    handlerLogout() {
      localStorage.removeItem("token");
      this.$store.dispatch("auth/clearUser");
      this.$router.push("/");
    },
    handleClose() {
      this.config = false;
    },
    handlerHelp() {
      console.log("Handler Help");
    },
    handlerCreate() {
      console.log("Dialog Create");
    },
    handlerEdit() {
      console.log("Dialog Edit");
    },
    handlerDelete() {
      console.log("Dialog Delete");
      this.$emit("onDeleteLots");
      this.$emit("changeMode");
    },
    goToUsers() {
      this.$router.push("admin/users");
    },
    goToFiles() {
      this.$router.push("admin/files");
    },

    async handlerSave(data) {
      let coordinatesList = this.$store.getters["mapa/getCoordinates"];
      const coordinates =
        this.$fileService.configureCoordinates(coordinatesList);

      const dataLote = {
        properties: {
          fid: data.fid,
          gleba: this.$fileService.findPropertie(data.loteData, "gleba"),
          quadra: this.$fileService.findPropertie(data.loteData, "quadra"),
          lote: this.$fileService.findPropertie(data.loteData, "lote"),
          area: this.$fileService.findPropertie(data.loteData, "area"),
          plano: this.$fileService.findPropertie(data.loteData, "plano"),
          gab: this.$fileService.findPropertie(data.loteData, "gab"),
          pos: null,
          cas: null,
          man: null,
          reajuste: null,
          index: null,
          custo: this.$fileService.findPropertie(data.loteData, "custo"),
          cod_comprador: this.$fileService.findPropertie(data.loteData, "cod_comprador"),
          nome_comprador: this.$fileService.findPropertie(data.loteData, "nome_comprador"),
          sit: null,
          sit2: data.properties.sit2,
          sit3: null,
        },
        geometry: {
          coordinates: coordinates,
          type: "MultiPolygon",
        },
        observation: data.observation,
        type: data.type,
      };

      const token = localStorage.getItem("token");
      const config = { headers: { Authorization: `Bearer ${token}` } };
      try {
        let response = await this.$http.post(
          "/loteamentos/create",
          dataLote,
          config
        );
        if (response.status === 200) {
          this.$toasted.success("Lote criado com sucesso!", {
            icon: "check",
            duration: 2000,
          });
          this.create = false;
          this.$store.dispatch("mapa/setCreateMode", false);
          this.$store.dispatch("mapa/setNextStepCreate", false);
          this.$store.dispatch("mapa/setCoordinates", []);
          // Atualiza o estado com o novo lote na lista
          // console.log('***OptionsBar: ', lotes);
          let lotes = this.loteamento;
          // console.log('***OptionsBar/Before: ', lotes);
          lotes.push(response.data.loteamento);
          // console.log('***OptionBar/After: ', lotes);
          // this.$store.dispatch("mapa/setLotes", lotes);
          this.$emit("reload");
        }
      } catch (error) {
        this.$toasted.error(error.message, {
          icon: "error_outline",
          duration: 2000,
        });
      }
    },
    // async setAdmin() {
    //   const token = localStorage.getItem("token");
    //   const config = { headers: { Authorization: `Bearer ${token}` } };
    //   try {
    //     let response = await this.$http.post(
    //       "/auth/set-admin",
    //       {
    //         email: this.adminEmail,
    //       },
    //       config
    //     );
    //     if (response.status === 200) {
    //       this.$toasted.success("Admin criado com sucesso!", {
    //         icon: "check",
    //         duration: 2000,
    //       });
    //       this.admin = false;
    //     }
    //   } catch (error) {
    //     this.$toasted.error(error.message, {
    //       icon: "error_outline",
    //       duration: 2000,
    //     });
    //   }
    // },
    handlerCancel() {
      console.log("Dialog Cancel");
    },
    // handlerSettings() {
    //   this.config = !this.config;
    //   this.$emit("changeMode");
    // },
    openSettings() {
      this.config = true;
      this.$emit("changeMode");
    },
    closeSettings() {
      this.config = false;
      if (this.editmode) {
        this.$emit("changeMode");
      }
    },
  },
  computed: {
    permissionLevel() {
      return this.$store.getters["auth/getPermissionLevel"];
    },
    createMode() {
      return this.$store.getters["mapa/getCreateMode"];
    },
    nextStepCreate() {
      return this.$store.getters["mapa/getNextStepCreate"];
    },
  },
};
</script>
<style scoped>
.lateral-bar-right-configs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: 200px;
  position: fixed;
  z-index: 800;
  right: 0px;
}

.lateral-bar-center-configs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: 200px;
  position: fixed;
  z-index: 800;
  left: 65%;
  transform: translate(-65%);
}

.navigation-area {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.action-buttons {
  display: flex;
  justify-content: space-between;
  margin: 8px;
  height: 43px;
}

.dialog {
  margin-right: 16px;
}

.input-admin {
  padding: 8px;
  margin-bottom: 8px;
  height: 32px;
  border: 1px solid #aaa;
}
@media screen and (min-width: 480px) and (max-width: 768px) {
  .lateral-bar-right-configs {
    z-index: 2;
  }
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  .lateral-bar-right-configs {
    z-index: 2;
  }
}
</style>
