<template>
  <div class="container">
    <h1>
      404 Not found
    </h1>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fbfbfb;
  width: 100% !important;
  height: 100vh;
}
</style>
