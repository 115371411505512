export default class {
  constructor(axios) {
    this.axios = axios;
  }

  getConfig() {
    let token = localStorage.getItem("token");
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
  }

  deleteLoteamento(lote_id, loteamento) {
    const index = loteamento.map((item) => item._id).indexOf(lote_id);
    loteamento.splice(index, 1);
    return loteamento;
  }

  findPropertie(list, value) {
    for (let item of list) {
      if (item["key"] === value) {
        return item["value"];
      }
    }
  }

  async getLote(lote_id) {
    let url = `loteamentos/find/${lote_id}/`;
    return await this.axios.get(url, this.getConfig());
  }

  async updateLoteamento(newLote, loteamento) {
    // console.log('***LoteService: ', newLote, loteamento);
    const response = await this.getLote(newLote.id);
    var oldLote = response.data.loteamento;
    const dataLote = {
      _id: oldLote._id,
      type: oldLote.type,
      properties: {
        fid: newLote.fid,
        gleba: this.findPropertie(newLote.loteData, "gleba"),
        quadra: this.findPropertie(newLote.loteData, "quadra"),
        lote: this.findPropertie(newLote.loteData, "lote"),
        area: this.findPropertie(newLote.loteData, "area"),
        plano: this.findPropertie(newLote.loteData, "plano"),
        gab: this.findPropertie(newLote.loteData, "gab"),
        pos: oldLote["properties"]["pos"],
        cas: oldLote["properties"]["cas"],
        man: oldLote["properties"]["man"],
        reajuste: oldLote["properties"]["reajuste"],
        index: oldLote["properties"]["index"],
        custo: this.findPropertie(newLote.loteData, "custo"),
        cod_comprador: this.findPropertie(newLote.loteData, "cod_comprador"),
        nome_comprador: this.findPropertie(newLote.loteData, "nome_comprador"),
        sit: oldLote["properties"]["sit"],
        sit2: this.findPropertie(newLote.loteData, "situacao"),
        sit3: oldLote["properties"]["sit3"],
      },
      geometry: oldLote["geometry"],
      observation: this.findPropertie(newLote.loteData, "observacao"),
    };
    const index = loteamento.map((item) => item._id).indexOf(newLote.id);
    // console.log('index: ', loteamento);
    loteamento[index] = dataLote;
    return loteamento;
  }
}
