<template>
  <div id="container">
    <header
      v-bind:style="{
        backgroundImage:
          'url(https://itasolblog.files.wordpress.com/2015/12/sobrenos2.jpg?w=1400&h=882&crop=1)',
      }"
    >
      <h1>ITASOL Empreendimentos I. & M.</h1>
    </header>
    <div id="content">
      <main>
        <div id="wrapper">
          <button @click="$router.push('/')">Voltar</button>
          <h1>Sobre Nós</h1>
        </div>
        <p class="main-text">
          Fundada no final da década de 1970, a Itasol Empreendimentos
          Imobiliários & Mineração é proprietária de cerca de 750 hectares em
          Manilha, distrito da cidade de Itaboraí, Rio de Janeiro. <br /><br />

          Margeada por duas rodovias federais (BR101 e BR493) e três estaduais
          (RJ104, RJ114 e RJ116), a supracitada área está a apenas 45 minutos da
          capital fluminense e a 8 minutos do Complexo Petroquímico do Rio de
          Janeiro – COMPERJ, evidenciando a sua potencialidade logística e
          estratégica para a formatação de novos negócios, especialmente com a
          duplicação da Rodovia Magé-Manilha, transformando a BR493 no terceiro
          e último trecho do Arco Metropolitano do Rio de Janeiro.
        </p>
        <img
          src="../../assets/areaitasol.jpg"
          alt="Itasol Area"
          width="1055"
          height="675"
        />
        <p class="main-text" id="main-text-last">
          Além da ampla capacidade imobiliária, a região ainda é contemplada por
          uma vasta jazida de argila – matéria-prima utilizada em diversos
          setores como a indústria ceramista; de semicondutores (informática);
          automotiva; e construção civil – o que apenas ratifica sua
          singularidade. <br />
          <br />

          Esta pluralidade de possibilidades vem permitindo a Itasol prospectar
          diferentes parceiros para o desenvolvimento e execução de projetos
          bilaterais que tenham por objetivo não somente o estabelecimento de
          uma frutífera relação win/win, bem como a maximização do
          aproveitamento econômico destas centenas de hectares pertencentes a
          empresa. Como consequência, somente nesta década diversos lotes foram
          comercializados.
          <br />
          <br />

          Portanto, se a sua empresa planeja investir em Itaboraí no ramo
          imobiliário ou mineração, entre em contato com a Itasol. Temos certeza
          que a viabilização do seu projeto funde-se com a nossa história e
          compromisso de desenvolver harmoniosamente a região.
        </p>
      </main>
    </div>
    <footer
      v-bind:style="{
        backgroundImage:
          'url(https://itasolblog.files.wordpress.com/2015/12/cropped-cropped-cropped-bg-bemviver2.jpg)',
      }"
    >
      <p>
        2016 Itasol Empreendimentos Imobiliários & Mineração LTDA. Todos os
        direitos reservados.
      </p>
    </footer>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
#container {
  width: 100%;
  height: 100%;
  font-family: "Cinzel Regular", serif;
  background: #e6ddcd;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#content {
  width: 90%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 90%;
  height: 350px;
}

header > h1 {
  font-weight: 400;
  font-size: 1.5rem;
  z-index: 10;
  color: #fcfbf9;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  text-shadow: 0.1em 0.1em 0.2em black;
}

/* Main */
main {
  background-color: white;
  width: 60%;
}

main > img {
  width: 100%;
  height: auto;
  margin: 2rem 0;
}

#wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 3rem;
}

#wrapper > button {
  font-family: "Quattrocento Regular", sans-serif;
  background-color: transparent;
  text-transform: uppercase;
  color: #a9a9a9;
  font-weight: 700;
  font-size: 1rem;
  transition: all 0.2s ease-in-out;
  margin: 3rem 0 0.5rem 0;
}

#wrapper > button:hover {
  cursor: pointer;
  filter: brightness(0.8);
}

#wrapper > h1 {
  font-size: 2.5rem;
  font-family: "Cinzel Regular", serif;
  text-transform: uppercase;
  font-weight: 400;
  color: #2a6263;
  margin-left: 0.2rem;
  margin-top: 0.5rem;
}

.main-text {
  font-family: "Quattrocento Regular", sans-serif;
  color: #726b60;
  font-weight: 400;
  font-size: 1.125rem;
  text-align: justify;
  line-height: 1.7rem;
}

#main-text-last {
  padding-bottom: 4.5rem;
}

/* Footer */
footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 90%;
  /* filter: brightness(0.4); */
}

footer > p {
  padding: 1rem 1rem;
  color: #fcfbf9;
}

@media screen and (min-width: 480px) and (max-width: 768px) {
  header {
    width: 100%;
  }

  header > h1 {
    font-size: 1.2rem;
    margin: 0 1rem;
  }

  #content {
    width: 100%;
  }

  main {
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #wrapper > h1 {
    font-size: 2rem;
  }

  footer {
    width: 100%;
  }
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  header {
    width: 100%;
  }

  header > h1 {
    font-size: 1.2rem;
    margin: 0 1rem;
  }

  #content {
    width: 100%;
  }

  main {
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #wrapper > h1 {
    font-size: 2rem;
  }

  footer {
    width: 100%;
  }
}
</style>