<template>
	<div class="subtitle-card">
		<div class="header" @click="$emit('close')">
			<h2>Legenda</h2>
		</div>
		<div v-for="{ label, color } in subtitles" :key="label" class="subtitles">
			<div class="line">
				<div class="quad-color" :style="`background:${color};`"></div>
				<span>{{ label }}</span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				subtitles: [
					{
						label: "Atrasados",
						color: "#a00",
					},
					{
						label: "Em dia",
						color: "#0a0",
					},
					{
						label: "Escriturados",
						color: "#fff",
					},
					{
						label: "Estoque",
						color: "#aaa",
					},
					{
						label: "Indefinidos",
						color: "#d91",
					},
					{
						label: "Quitados",
						color: "#00a",
					},
					{
						label: "Reservados",
						color: "#aa2",
					},
				],
			};
		},
	};
</script>

<style  scoped>
	.subtitle-card {
		background: #f2f2f2;
		width: 140px;
		border-radius: 5px;
		display: flex;
		flex-direction: column;
		height: 280px;
		padding: 16px;
		box-shadow: 12px 12px rgba(0, 0, 0, 0.2);
		margin-bottom: 125px;
		position: absolute;
		z-index: 700;
		right: 25px;
	}

	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.header span {
		cursor: pointer;
		padding: 4px;
	}

	h2 {
		padding: 8px 0px;
		text-align: left;
		font-size: 16px;
		font-weight: bold;
	}

	.subtitles {
		display: flex;
		flex-direction: column;
	}

	.line {
		display: flex;
		margin: 8px 0px;
	}

	.line span {
		flex: 1;
		background: #d8d8d8;
		color: #626262;
		font-size: 14px;
		padding: 2px;
	}

	.quad-color {
		margin-right: 8px;
		width: 16px;
		height: 16px;
		border: 1px solid #ccc;
	}
</style>

