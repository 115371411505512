<template>
  <button><slot></slot></button>
</template>

<script>
export default {}
</script>

<style scoped>
button {
  cursor: pointer;
  border: none;
  background-color: #0b245b;
  color: white;
  padding: 8px 16px;
}

button:hover {
  background-color: #091d49;
}

button:active {
  background-color: #10285a;
}
</style>
