<template>
  <div class="container">
    <div class="trapezio">
      <Registrar
        id="trapezio-register"
        :title="true"
        @cadastrar="cadastrar"
      ></Registrar>
      <div class="teste"></div>
    </div>
    <div>
      <h1 class="logo"><strong>ITA-SOL</strong></h1>
    </div>
    <div class="quadrado">
      <div class="login">
        <Login v-if="!showRegister" @login="login" @esqueci="esqueci"></Login>
        <Registrar :title="false" v-else @cadastrar="cadastrar"></Registrar>
      </div>
      <div v-if="!showRegister" class="register-type">
        Não tem conta?
        <span @click="showRegister = true">Registre-se Aqui</span>
      </div>
      <div v-else class="register-type">
        Já possui conta?
        <span @click="showRegister = false">Entre Aqui</span>
      </div>
    </div>
    <div class="vazio"></div>
    <div class="footer-logo">
      <img src="../assets/azimute-logo.png" alt="Azimute Engenharia" />
    </div>
  </div>
</template>

<script>
import Login from "../components/Login/Login.vue";
import Registrar from "../components/Login/Registrar.vue";

export default {
  components: {
    Login,
    Registrar,
  },
  data() {
    return {
      email: "",
      password: "",
      name: "",
      showRegister: false,
    };
  },
  methods: {
    login: function (email, password) {
      this.$http
        .post("auth/authenticate", {
          email: email,
          password: password,
        })
        .then((res) => {
          localStorage.setItem("token", res.data.token);
          this.$store.commit("auth/SET_USER", res.data.user);
          this.$store.commit("auth/SET_TOKEN", res.data.token);
          this.$store.commit(
            "auth/SET_PERMISSION_LEVEL",
            res.data.user.accessLevel
          );
          this.$toasted.success("Login realizado com sucesso!", {
            icon: "check",
            duration: 2000,
          });
          this.$router.push("home");
        })
        .catch((err) => {
          this.$toasted.error("Ocorreu um erro ao fazer login!", {
            icon: "error_outline",
            duration: 3000,
          });
          console.log(err);
        });
    },
    cadastrar: function (nome, email, password) {
      this.$http
        .post("users/create", {
          name: nome,
          email: email,
          password: password,
        })
        .then((res) => {
          res;
          this.$toasted.success("Cadastro realizado com sucesso!", {
            icon: "check",
            duration: 2000,
          });

          this.login(email, password);
        })
        .catch((err) => {
          this.$toasted.error("Ocorreu um erro ao cadastrar!", {
            icon: "error_outline",
            duration: 3000,
          });
          console.log(err);
        });
    },
    esqueci: function (email) {
      this.$http
        .post("users/forgot-password", {
          email: email,
        })
        .then(() => {
          this.$toasted.success("Verifique no seu email nossa mensagem!", {
            icon: "check",
            duration: 3500,
          });
        })
        .catch(() => {
          this.$toasted.error("Ocorreu um erro ao enviar o email!", {
            icon: "error_outline",
            duration: 3000,
          });
        });
    },
  },
};
</script>
<style scoped>
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-image: url("../assets/itasol.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  overflow: hidden;
}
.trapezio {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 600px;
  overflow: hidden;
  /* background: rgb(218, 218, 218);
  border-top: 50px solid transparent;  */
  background-image: url("../assets/trapezio.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-attachment: fixed;
  background-position: left;
  background-size: cover;
}

#trapezio-register {
  padding-left: 10%;
}

.quadrado {
  width: 500px;
  z-index: 2;
  height: 350px;
  margin-top: 2px;
  background: rgb(218, 218, 218);
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.vazio {
  width: 50%;
  color: hotpink;
}
.teste {
  color: greenyellow;
  width: 20%;
}
.teste {
  color: greenyellow;
  width: 20%;
}

.register-type {
  display: none;
}

@media screen and (min-width: 768px) and (max-width: 1360px) {
  .register-type {
    display: block;
    margin-top: 1.2rem;
    font-size: 1.1rem;
    font-weight: 500;
  }

  .register-type > span {
    color: #3858a8;
    cursor: pointer;
  }
  .trapezio {
    display: none;
  }
  .quadrado {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    width: 400px;
    height: 400px;
  }
}

@media screen and (min-width: 480px) and (max-width: 768px) {
  .register-type {
    display: block;
    margin-top: 1.2rem;
    font-size: 1.1rem;
    font-weight: 500;
  }

  .register-type > span {
    color: #3858a8;
    cursor: pointer;
  }
  .trapezio {
    display: none;
  }
  .quadrado {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    width: 400px;
    height: 400px;
  }
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  .trapezio {
    display: none;
  }
  .quadrado {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    width: 325px;
    height: 400px;
  }
  .register-type {
    display: block;
    margin-top: 1.2rem;
    font-size: 1.1rem;
    font-weight: 500;
  }

  .register-type > span {
    color: #3858a8;
    cursor: pointer;
  }
}
</style>
