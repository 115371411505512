const state = {
  lote: {},
  fileSelected: {},
  filesDelete: [],
  files: [],
  images: [],
  editMode: false,
};

const getters = {
  getLote(state) {
    return state.lote;
  },
  getFileList(state) {
    return state.files;
  },
  getImageList(state) {
    return state.images;
  },
  getFileSelected(state) {
    return state.fileSelected;
  },
  getFilesDelete(state) {
    return state.filesDelete;
  },
  getEditMode(state) {
    return state.editMode;
  },
};

const mutations = {
  SET_LOTE(state, value) {
    state.lote = value;
  },
  SET_FILE_LIST(state, value) {
    state.files = value;
  },
  SET_FILE_SELECTED(state, value) {
    state.fileSelected = value;
  },
  SET_EDIT_MODE(state, value) {
    state.editMode = value;
  },
  SET_FILES_DELETE(state, value) {
    state.filesDelete.push(value);
  },
  SET_IMAGE_LIST(state, value) {
    state.images = value;
  },
  REMOVE_FILE_DELETE: (state, value) => {
    const index = state.filesDelete.map(item => item._id).indexOf(value);
    state.filesDelete.splice(index, 1);
  },
  REMOVE_FILE_LIST: (state, payload) => {
    if(payload.state === 'files'){
      const index = state.files.map(item => item._id).indexOf(payload._id);
      state.files.splice(index, 1);
    } else {
      const index = state.images.map(item => item._id).indexOf(payload._id);
      state.images.splice(index, 1);
    }
  },
  CLEAR_FILE_DELETED: (state, value) => {
    state.filesDelete = value;
  }
};

const actions = {
  setLote({ commit }, value) {
    commit("SET_LOTE", value);
  },
  setFileList({ commit }, value) {
    commit("SET_FILE_LIST", value);
  },
  setImageList({ commit }, value) {
    commit("SET_IMAGE_LIST", value);
  },
  setFileSelected({ commit }, value) {
    commit("SET_FILE_SELECTED", value);
  },
  setEditMode({ commit }, value) {
    commit("SET_EDIT_MODE", value);
  },
  setFilesDelete({ commit }, value) {
    commit("SET_FILES_DELETE", value);
  },
  removeFileDelete({ commit }, value) {
    commit("REMOVE_FILE_DELETE", value);
  },
  removeFileList({ commit }, payload) {
    commit("REMOVE_FILE_LIST", payload);
  },
  clearFileDelete({ commit }) {
    commit("CLEAR_FILE_DELETED", []);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
