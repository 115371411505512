export default class FileServices {
  constructor(axios) {
    this.axios = axios;
  }

  getConfig() {
    let token = localStorage.getItem("token");
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
  }

  async uploadFile(fileForm) {
    let url = "fileman/upload";
    return await this.axios.post(url, fileForm, this.getConfig());
  }

  async deleteFile(fileForm) {
    let url = "fileman/delete";

    let token = localStorage.getItem("token");

    return await this.axios.delete(url, {
      data: fileForm,
      headers: { Authorization: `Bearer ${token}` },
    });
  }

  async loadFiles(fid, type) {
    let url = `fileman/list/${fid}`;
    const response = await this.axios.get(url, this.getConfig());
    const files = response.data.data;
    var files_type;
    switch (type) {
      case "pdf":
        files_type = files.filter(
          (file) => file.file_format === "application/pdf"
        );
        break;
      case "image":
        files_type = files.filter(
          (file) =>
            file.file_format === "image/png" ||
            file.file_format === "image/jpg" ||
            file.file_format === "image/jpeg" ||
            file.file_format === "video/mp4"
        );
        break;
      case "video":
        files_type = files.filter((file) => file.file_format === "video/mp4");
        break;
      default:
        break;
    }
    return files_type;
  }

  async getLote(lote_id) {
    let url = `loteamentos/find/${lote_id}/`;
    return await this.axios.get(url, this.getConfig());
  }

  findPropertie(list, value) {
    for (let item of list) {
      if (item["key"] === value) {
        return item["value"];
      }
    }
  }

  configureCoordinates(list) {
    var array = [];
    var unite = [];
    for (let coord of list[0]) {
      unite.push(coord.lng);
      unite.push(coord.lat);

      array.push(unite);
      unite = [];
    }
    return [[array]];
  }

  async updateLote(newLote) {
    const response = await this.getLote(newLote.id);
    var oldLote = response.data.loteamento;
    const dataLote = {
      properties: {
        fid: newLote.fid,
        gleba: this.findPropertie(newLote.loteData, "gleba"),
        quadra: this.findPropertie(newLote.loteData, "quadra"),
        lote: this.findPropertie(newLote.loteData, "lote"),
        area: this.findPropertie(newLote.loteData, "area"),
        plano: this.findPropertie(newLote.loteData, "plano"),
        gab: this.findPropertie(newLote.loteData, "gab"),
        pos: oldLote["properties"]["pos"],
        cas: oldLote["properties"]["cas"],
        man: oldLote["properties"]["man"],
        reajuste: oldLote["properties"]["reajuste"],
        index: oldLote["properties"]["index"],
        custo: this.findPropertie(newLote.loteData, "custo"),
        cod_comprador: this.findPropertie(newLote.loteData, "cod_comprador"),
        nome_comprador: this.findPropertie(newLote.loteData, "nome_comprador"),
        sit: oldLote["properties"]["sit"],
        sit2: this.findPropertie(newLote.loteData, "situacao"),
        sit3: oldLote["properties"]["sit3"],
      },
      observation: this.findPropertie(newLote.loteData, "observacao"),
    };

    let url = `loteamentos/update/${oldLote._id}`;
    let token = localStorage.getItem("token");
    return await this.axios.put(url, dataLote, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }

  createList(value) {
    var aux = [];
    Object.keys(value).forEach(function(item) {
      aux.push({
        id: value[item]._id,
        fid: value[item].properties.fid,
        color: value[item].properties.sit2,
        qtd: parseInt(value[item].properties.fid),
        loteData: [
          {
            label: "Observação",
            key: "observacao",
            value: value[item].observation,
            type: "textarea",
          },
          {
            label: "Lote",
            value: value[item].properties.lote,
            key: "lote",
            type: "text",
          },
          {
            label: "Quadra",
            value: value[item].properties.quadra,
            key: "quadra",
            type: "text",
          },
          {
            label: "Gleba",
            value: value[item].properties.gleba,
            key: "gleba",
            type: "text",
          },
          {
            label: "Área(m2)",
            value: value[item].properties.area,
            key: "area",
            type: "text",
          },
          {
            label: "Situação",
            value: value[item].properties.sit2,
            key: "situacao",
            type: "select",
          },
          {
            label: "Plano",
            value: value[item].properties.plano,
            key: "plano",
            type: "text",
          },
          {
            label: "Gab",
            value: value[item].properties.gab,
            key: "gab",
            type: "text",
          },
          {
            label: "Custo",
            value: value[item].properties.custo,
            key: "custo",
            type: "text",
          },
          {
            label: "Cód. Comprador",
            value: value[item].properties.cod_comprador,
            key: "cod_comprador",
            type: "text",
          },
          {
            label: "Nome Comprador",
            value: value[item].properties.nome_comprador,
            key: "nome_comprador",
            type: "text",
          },
        ],
      });
    });
    return aux;
  }
}
