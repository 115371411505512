<template>
  <div class="bodyRegister">
    <h1 v-if="title"><strong>Não possui uma conta?</strong></h1>
    <div class="inputs" v-for="(option, index) in options" :key="index">
      <input
        :type="option.type"
        v-model="option.result"
        :placeholder="option.label"
        v-on:keyup.enter="cadastrar"
      />
      <span
        v-if="option.label === `Senha` && !option.see"
        class="material-icons icon"
        @click="visibility"
      >
        visibility
      </span>
      <span
        v-else
        v-show="option.label === `Senha` && option.see"
        class="material-icons icon"
        @click="visibility"
      >
        visibility_off
      </span>
    </div>

    <Button class="buttonLogin" @click.native="cadastrar" v-on:keyup.enter="cadastrar">
      Cadastrar-se
    </Button>
  </div>
</template>

<script>
import Button from "../global/GenericButton";
export default {
  props: ["title"],
  components: {
    Button,
  },
  data() {
    return {
      options: [
        { label: "Nome e Sobrenome", see: false, result: "", type: "text" },
        { label: "E-mail", see: false, result: "", type: "text" },
        { label: "Senha", see: true, result: "", type: "password" },
        { label: "Confirme a senha", see: true, result: "", type: "password" },
      ],
    };
  },
  methods: {
    cadastrar: function () {
      let pwd = this.options[2].result;
      let confirmPwd = this.options[3].result;
      if (pwd === confirmPwd) {
        this.$emit(
          "cadastrar",
          this.options[0].result,
          this.options[1].result,
          this.options[2].result
        );
      } else {
        this.$toasted.error("As senhas não são iguais, tente novamente!", {
          icon: "error_outline",
          duration: 3000,
        });
      }
    },
    visibility: function () {
      this.options[2].see = !this.options[2].see;
      if (this.options[2].type == "password") {
        this.options[2].type = "text";
        this.options[3].type = "text";
      } else {
        this.options[2].type = "password";
        this.options[3].type = "password";
      }
    },
  },
};
</script>

<style  scoped>
.bodyRegister {
  width: 100% !important;
}

input {
  width: 100%;
  height: 2px;
  margin-bottom: 20px;
  border: none;
  font-size: 20px;
  padding: 25px;
}

input:focus {
  border: none;
}

.inputs {
  display: flex;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  height: 50px;
  width: 80px;
  font-size: 30px;
  cursor: pointer;
}

.buttonLogin {
  width: 100%;
  margin-top: 10px;
  font-size: 20px;
}

h1 {
  text-align: left;
  margin-bottom: 20px;
  font-size: 24px;
}
</style>