<template>
  <div id="container">
    <div id="content">
      <header>
        <img
          src="../../assets/mineracao-bg.jpg"
          width="100%"
          height="400"
          alt="mineracao-bg"
          scale="0"
        />
        <div id="bg-title">
          <h1>ITASOL Empreendimentos I. & M.</h1>
        </div>
      </header>
      <section>
        <div id="wrapper">
          <button @click="$router.push('/')">Voltar</button>
          <h1>Mineração</h1>
        </div>
        <p>
          A Itasol guarda para si grande interesse no aproveitamento adequado de
          sua área, especialmente no que diz respeito a implementação de
          diferentes empreendimentos (além do setor imobiliário). E a mineração
          é um destes.
        </p>
        <p>
          Boa parte dos hectares pertencentes a empresa é contemplado por uma
          extensa jazida de argila, sendo a extração desta um dos grandes
          diferenciais da área. Vale lembrar que a Itasol também detém o direito
          de lavra nestes hectares, o que potencializa o estabelecimento de
          novas parcerias.
        </p>
        <p>
          A argila extraída no Rio de Janeiro e região é amplamente utilizada
          como insumo em diferentes processos produtivos como no caso da
          indústria automotiva, cerâmica e de tecnologia, bem como no setor da
          construção civil, entre outros.
        </p>
        <p>
          É em face desta vigente riqueza geológica que a Itasol mantém-se
          aberta ao estabelecimento de parcerias estratégicas com firmas que
          possuam expertise na extração deste tipo minério.
        </p>
        <p>
          Assim, se a sua empresa possui know-how e interesse no desenvolvimento
          de negócios neste segmento, entre em contato conosco! Estamos seguros
          que com diálogo e planejamento podemos estabelecer objetivos comuns e
          economicamente viáveis.
        </p>
      </section>
      <footer
        v-bind:style="{
          backgroundImage:
            'url(https://itasolblog.files.wordpress.com/2015/12/cropped-cropped-cropped-bg-bemviver2.jpg)',
        }"
      >
        <p>
          2016 Itasol Empreendimentos Imobiliários & Mineração LTDA. Todos os
          direitos reservados.
        </p>
      </footer>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
#container {
  width: 100%;
  height: 100%;
  font-family: "Cinzel Regular", serif;
  background: #e6ddcd;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#content {
  width: 90%;
  background-color: #fcfbf9;
}

#wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 3rem;
}

#wrapper > button {
  font-family: "Quattrocento Regular", sans-serif;
  background-color: transparent;
  text-transform: uppercase;
  color: #a9a9a9;
  font-weight: 700;
  font-size: 1rem;
  transition: all 0.2s ease-in-out;
  margin: 3rem 0 0.5rem 0;
}

#wrapper > button:hover {
  cursor: pointer;
  filter: brightness(0.8);
}

#wrapper > h1 {
  font-size: 2.5rem;
  font-family: "Cinzel Regular", serif;
  text-transform: uppercase;
  font-weight: 400;
  color: #2a6263;
  /* margin-left: 0.2rem; */
  margin-top: 0.5rem;
}

header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 100%;
}

#bg-title {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 1.4rem;
  font-style: normal;
  text-shadow: 1px 1px 2px rgb(0 0 0 / 75%);
  z-index: 10;
  color: #fcfbf9;
  text-transform: uppercase;
  letter-spacing: 2px;
}

section {
  font-family: "Quattrocento Regular", serif;
  width: 66%;
  font-size: 1.2rem;
  font-weight: 200;
  color: #726b60;
  box-sizing: inherit;
  margin-left: auto;
  margin-right: auto;
  padding: 5% 4%;
}
section > p {
  text-align: justify;
  padding-bottom: 4%;
  line-height: 1.6rem;
}
section > button,
h1 {
  display: flex;
  justify-content: flex-start;
}
section > h1 {
  color: #2a6263;
  font-size: 2.4rem;
  font-weight: lighter;
  margin: 0.7em 0;
  padding-bottom: 1em;
  text-transform: uppercase;
}
.home {
  background: transparent;
  display: flex;
  float: left;
  cursor: pointer;
  position: relative;
  color: darkgray;
  text-transform: uppercase;
  font-weight: 400;
  transition: all 0.2s ease-in;
}
.link-contact {
  color: #2a6263;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.link-contact:hover {
  text-decoration: underline;
  color: #83b6cc;
}

footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  /* filter: brightness(0.4); */
}

footer > p {
  padding: 1rem 1rem;
  color: #fcfbf9;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  img {
    height: auto;
    max-width: 100%;
  }
  #content {
    width: 100%;
  }

  section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0%;
    padding: 4%;
  }
  section > p {
    text-align: justify;
    padding-bottom: 3%;
  }
}

@media screen and (min-width: 480px) and (max-width: 768px) {
  img {
    height: auto;
    max-width: 100%;
  }
  #content {
    width: 100%;
  }

  #wrapper {
    width: 100%;
    align-items: center;
  }

  #wrapper > h1 {
    font-size: 2rem;
  }

  section {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0%;
    padding: 4%;
  }
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  img {
    width: 100%;
    height: auto;
  }
  #content {
    width: 100%;
  }

  #wrapper > h1 {
    font-size: 2rem;
  }

  #wrapper {
    width: 100%;
    align-items: center;
  }
  #bg-title {
    width: 50%;
    font-weight: 300;
    font-size: 1.4rem;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 1px;
  }
  section {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: auto;
    padding: 20px;
  }
  section > p {
    text-align: justify;
    padding-bottom: 10%;
    font-size: 1.2rem;
  }
}
</style>