<template>
  <div class="lateral-bar-right">
    <div class="navigation-area">
      <SubtitleCard v-if="subtitle" @close="subtitle = !subtitle" />
      <Button v-if="!subtitle" @click.native="subtitle = !subtitle">
        <span>legenda</span>
      </Button>
      <div class="action-buttons">
        <Button @click.native="$emit('geoloc')">
          <img
            src="../../assets/icons/place.svg"
            alt
            height="24"
            class="logo"
          />
        </Button>
        <Button @click.native="$emit('local')">
          <img src="../../assets/icons/3d.svg" alt height="24" class="logo" />
        </Button>
      </div>
      <div class="action-buttons">
        <Button @click.native="$emit('home')">
          <img src="../../assets/icons/home.svg" alt height="24" class="logo" />
        </Button>
        <Button @click.native="$emit('satelite')">
          <img
            src="../../assets/icons/planet.svg"
            alt
            height="24"
            class="logo"
          />
        </Button>
      </div>
      <ZoomButtons
        @zoomplus="$emit('zoomplus')"
        @zoommine="$emit('zoommine')"
      />
    </div>
  </div>
</template>
<script>
import Button from './Button'
import ZoomButtons from './ZoomButtons'
import SubtitleCard from '../SubtitleCard'
export default {
  components: {
    Button,
    ZoomButtons,
    SubtitleCard
  },
  data() {
    return {
      subtitle: false,
      config: false,
      options: [
        { label: 'Adicionar lotes', event: 'onCreate' },
        { label: 'Excluir lotes', event: 'onDelete' }
      ]
    }
  },
  methods: {
    handlerCreate() {
      console.log('Dialog Create')
    },
    handlerEdit() {
      console.log('Dialog Edit')
    },
    handlerDelete() {
      console.log('Dialog Delete')
    },
    handlerSave() {
      console.log('Dialog Save')
    },
    handlerCancel() {
      console.log('Dialog Cancel')
    }
  }
}
</script>
<style scoped>
.lateral-bar-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: 200px;
  position: fixed;
  z-index: 800;
  right: 0px;
}

.navigation-area {
  display: flex;
  position: fixed;
  bottom: 25px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  right: 10px;
}

.action-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0px 0px 0px;
}
@media screen and (min-width: 480px) and (max-width: 768px) {
		.lateral-bar-right{
			z-index: 1;
		}
		
	}

	@media screen and (min-width: 0px) and (max-width: 480px) {
		.lateral-bar-right{
			z-index: 1;
		}
	}
</style>
