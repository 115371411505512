<template>
	<div class="container">
		<div class="button" @click="$emit('zoommine')">
			<img src="../../assets/icons/less.svg" alt height="24" class="logo" />
		</div>
		<span></span>
		<div class="button" @click="$emit('zoomplus')">
			<img src="../../assets/icons/plus.svg" alt height="24" class="logo" />
		</div>
	</div>
</template>

<script>
	export default {};
</script>

<style  scoped>
	.container {
		display: flex;
		align-items: center;
		justify-content: center;
		background: #f2f2f2;
		cursor: pointer;
		border-radius: 5px;

		box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.2);
		margin: 8px;
	}
	.button {
		display: flex;
		flex: 1;
		color: #2c3e50;
		align-items: center;
		justify-content: center;
		padding: 8px 16px;
	}

	.button:hover {
		background: #ececec;
	}

	.button:active {
		background: #cecece;
		box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
	}
	span {
		width: 1px;
		background-color: #bbb;
		height: 60%;
	}
	
</style>